export const BarData = [
  {
    Année: "2006",
    Immobilier: 8.16,
    ImmobilierColor: "hsl(229, 70%, 50%)",
    "Électroménager, électronique et mobilier": 0,
    "Électroménager, électronique et mobilierColor": "hsl(296, 70%, 50%)",
    Change: 0,
    ChangeColor: "hsl(97, 70%, 50%)",
    Glacier: 0,
    GlacierColor: "hsl(340, 70%, 50%)",
    "Agriculture, agroalimentaire": 0,
    "Agriculture, agroalimentaireColor": "hsl(340, 70%, 50%)",
    "Commerce de gros": 0,
    "Commerce de grosColor": "hsl(340, 70%, 50%)",
    "Construction, équipement": 0,
    "Construction, équipementColor": "hsl(340, 70%, 50%)",
    "Fonderie, équipement automobile": 0,
    "Fonderie, équipement automobileColor": "hsl(340, 70%, 50%)",
    "Valeurs mobilières & immobilières": 0,
    "Valeurs mobilières & immobilièresColor": "hsl(340, 70%, 50%)",
    "Salles & équipements sportifs": 0,
    "Salles & équipements sportifsColor": "hsl(340, 70%, 50%)",
  },
  {
    Année: "2007",
    Immobilier: 33.1,
    ImmobilierColor: "hsl(229, 70%, 50%)",
    "Électroménager, électronique et mobilier": 0.82,
    "Électroménager, électronique et mobilierColor": "hsl(296, 70%, 50%)",
    Change: 0,
    ChangeColor: "hsl(97, 70%, 50%)",
    Glacier: 0,
    GlacierColor: "hsl(340, 70%, 50%)",
    "Agriculture, agroalimentaire": 0,
    "Agriculture, agroalimentaireColor": "hsl(340, 70%, 50%)",
    "Commerce de gros": 0,
    "Commerce de grosColor": "hsl(340, 70%, 50%)",
    "Construction, équipement": 0,
    "Construction, équipementColor": "hsl(340, 70%, 50%)",
    "Fonderie, équipement automobile": 0,
    "Fonderie, équipement automobileColor": "hsl(340, 70%, 50%)",
    "Valeurs mobilières & immobilières": 0,
    "Valeurs mobilières & immobilièresColor": "hsl(340, 70%, 50%)",
    "Salles & équipements sportifs": 0,
    "Salles & équipements sportifsColor": "hsl(340, 70%, 50%)",
  },
  {
    Année: "2008",
    Immobilier: 74.3,
    ImmobilierColor: "hsl(229, 70%, 50%)",
    "Électroménager, électronique et mobilier": 0,
    "Électroménager, électronique et mobilierColor": "hsl(296, 70%, 50%)",
    Change: 0,
    ChangeColor: "hsl(97, 70%, 50%)",
    Glacier: 0,
    GlacierColor: "hsl(340, 70%, 50%)",
    "Agriculture, agroalimentaire": 0,
    "Agriculture, agroalimentaireColor": "hsl(340, 70%, 50%)",
    "Commerce de gros": 0,
    "Commerce de grosColor": "hsl(340, 70%, 50%)",
    "Construction, équipement": 0,
    "Construction, équipementColor": "hsl(340, 70%, 50%)",
    "Fonderie, équipement automobile": 0,
    "Fonderie, équipement automobileColor": "hsl(340, 70%, 50%)",
    "Valeurs mobilières & immobilières": 0,
    "Valeurs mobilières & immobilièresColor": "hsl(340, 70%, 50%)",
    "Salles & équipements sportifs": 0,
    "Salles & équipements sportifsColor": "hsl(340, 70%, 50%)",
  },
  {
    Année: "2009",
    Immobilier: 2.55,
    ImmobilierColor: "hsl(229, 70%, 50%)",
    "Électroménager, électronique et mobilier": 7,
    "Électroménager, électronique et mobilierColor": "hsl(296, 70%, 50%)",
    Change: 12,
    ChangeColor: "hsl(97, 70%, 50%)",
    Glacier: 0,
    GlacierColor: "hsl(340, 70%, 50%)",
    "Agriculture, agroalimentaire": 0,
    "Agriculture, agroalimentaireColor": "hsl(340, 70%, 50%)",
    "Commerce de gros": 0,
    "Commerce de grosColor": "hsl(340, 70%, 50%)",
    "Construction, équipement": 0,
    "Construction, équipementColor": "hsl(340, 70%, 50%)",
    "Fonderie, équipement automobile": 0,
    "Fonderie, équipement automobileColor": "hsl(340, 70%, 50%)",
    "Valeurs mobilières & immobilières": 0,
    "Valeurs mobilières & immobilièresColor": "hsl(340, 70%, 50%)",
    "Salles & équipements sportifs": 0,
    "Salles & équipements sportifsColor": "hsl(340, 70%, 50%)",
  },
  {
    Année: "2010",
    Immobilier: 0,
    ImmobilierColor: "hsl(229, 70%, 50%)",
    "Électroménager, électronique et mobilier": 27.9,
    "Électroménager, électronique et mobilierColor": "hsl(296, 70%, 50%)",
    Change: 47.3,
    ChangeColor: "hsl(97, 70%, 50%)",
    Glacier: 0.12,
    GlacierColor: "hsl(340, 70%, 50%)",
    "Agriculture, agroalimentaire": 0,
    "Agriculture, agroalimentaireColor": "hsl(340, 70%, 50%)",
    "Commerce de gros": 0,
    "Commerce de grosColor": "hsl(340, 70%, 50%)",
    "Construction, équipement": 0,
    "Construction, équipementColor": "hsl(340, 70%, 50%)",
    "Fonderie, équipement automobile": 0,
    "Fonderie, équipement automobileColor": "hsl(340, 70%, 50%)",
    "Valeurs mobilières & immobilières": 0,
    "Valeurs mobilières & immobilièresColor": "hsl(340, 70%, 50%)",
    "Salles & équipements sportifs": 0,
    "Salles & équipements sportifsColor": "hsl(340, 70%, 50%)",
  },
  {
    Année: "2011",
    Immobilier: 0.94,
    ImmobilierColor: "hsl(229, 70%, 50%)",
    "Électroménager, électronique et mobilier": 48.1,
    "Électroménager, électronique et mobilierColor": "hsl(296, 70%, 50%)",
    Change: 55.6,
    ChangeColor: "hsl(97, 70%, 50%)",
    Glacier: 0.23,
    GlacierColor: "hsl(340, 70%, 50%)",
    "Agriculture, agroalimentaire": 0,
    "Agriculture, agroalimentaireColor": "hsl(340, 70%, 50%)",
    "Commerce de gros": 0,
    "Commerce de grosColor": "hsl(340, 70%, 50%)",
    "Construction, équipement": 0,
    "Construction, équipementColor": "hsl(340, 70%, 50%)",
    "Fonderie, équipement automobile": 0,
    "Fonderie, équipement automobileColor": "hsl(340, 70%, 50%)",
    "Valeurs mobilières & immobilières": 0,
    "Valeurs mobilières & immobilièresColor": "hsl(340, 70%, 50%)",
    "Salles & équipements sportifs": 0,
    "Salles & équipements sportifsColor": "hsl(340, 70%, 50%)",
  },
  {
    Année: "2012",
    Immobilier: 17,
    ImmobilierColor: "hsl(229, 70%, 50%)",
    "Électroménager, électronique et mobilier": 18.1,
    "Électroménager, électronique et mobilierColor": "hsl(296, 70%, 50%)",
    Change: 44,
    ChangeColor: "hsl(97, 70%, 50%)",
    Glacier: 0.32,
    GlacierColor: "hsl(340, 70%, 50%)",
    "Agriculture, agroalimentaire": 0,
    "Agriculture, agroalimentaireColor": "hsl(340, 70%, 50%)",
    "Commerce de gros": 0,
    "Commerce de grosColor": "hsl(340, 70%, 50%)",
    "Construction, équipement": 0,
    "Construction, équipementColor": "hsl(340, 70%, 50%)",
    "Fonderie, équipement automobile": 0,
    "Fonderie, équipement automobileColor": "hsl(340, 70%, 50%)",
    "Valeurs mobilières & immobilières": 0,
    "Valeurs mobilières & immobilièresColor": "hsl(340, 70%, 50%)",
    "Salles & équipements sportifs": 0,
    "Salles & équipements sportifsColor": "hsl(340, 70%, 50%)",
  },
  {
    Année: "2013",
    Immobilier: 57.8,
    ImmobilierColor: "hsl(229, 70%, 50%)",
    "Électroménager, électronique et mobilier": 41.8,
    "Électroménager, électronique et mobilierColor": "hsl(296, 70%, 50%)",
    Change: 0.28,
    ChangeColor: "hsl(97, 70%, 50%)",
    Glacier: 0.24,
    GlacierColor: "hsl(340, 70%, 50%)",
    "Agriculture, agroalimentaire": 0,
    "Agriculture, agroalimentaireColor": "hsl(340, 70%, 50%)",
    "Commerce de gros": 0,
    "Commerce de grosColor": "hsl(340, 70%, 50%)",
    "Construction, équipement": 0,
    "Construction, équipementColor": "hsl(340, 70%, 50%)",
    "Fonderie, équipement automobile": 0,
    "Fonderie, équipement automobileColor": "hsl(340, 70%, 50%)",
    "Valeurs mobilières & immobilières": 0,
    "Valeurs mobilières & immobilièresColor": "hsl(340, 70%, 50%)",
    "Salles & équipements sportifs": 0,
    "Salles & équipements sportifsColor": "hsl(340, 70%, 50%)",
  },
  {
    Année: "2014",
    Immobilier: 45.3,
    ImmobilierColor: "hsl(229, 70%, 50%)",
    "Électroménager, électronique et mobilier": 47.8,
    "Électroménager, électronique et mobilierColor": "hsl(296, 70%, 50%)",
    Change: 0.59,
    ChangeColor: "hsl(97, 70%, 50%)",
    Glacier: 0.06,
    GlacierColor: "hsl(340, 70%, 50%)",
    "Agriculture, agroalimentaire": 4.2,
    "Agriculture, agroalimentaireColor": "hsl(340, 70%, 50%)",
    "Commerce de gros": 0,
    "Commerce de grosColor": "hsl(340, 70%, 50%)",
    "Construction, équipement": 0,
    "Construction, équipementColor": "hsl(340, 70%, 50%)",
    "Fonderie, équipement automobile": 0,
    "Fonderie, équipement automobileColor": "hsl(340, 70%, 50%)",
    "Valeurs mobilières & immobilières": 0,
    "Valeurs mobilières & immobilièresColor": "hsl(340, 70%, 50%)",
    "Salles & équipements sportifs": 0,
    "Salles & équipements sportifsColor": "hsl(340, 70%, 50%)",
  },
  {
    Année: "2015",
    Immobilier: 40.6,
    ImmobilierColor: "hsl(229, 70%, 50%)",
    "Électroménager, électronique et mobilier": 19.8,
    "Électroménager, électronique et mobilierColor": "hsl(296, 70%, 50%)",
    Change: 0.61,
    ChangeColor: "hsl(97, 70%, 50%)",
    Glacier: 0,
    GlacierColor: "hsl(340, 70%, 50%)",
    "Agriculture, agroalimentaire": 11.2,
    "Agriculture, agroalimentaireColor": "hsl(340, 70%, 50%)",
    "Commerce de gros": 0,
    "Commerce de grosColor": "hsl(340, 70%, 50%)",
    "Construction, équipement": 7.4,
    "Construction, équipementColor": "hsl(340, 70%, 50%)",
    "Fonderie, équipement automobile": 0,
    "Fonderie, équipement automobileColor": "hsl(340, 70%, 50%)",
    "Valeurs mobilières & immobilières": 0,
    "Valeurs mobilières & immobilièresColor": "hsl(340, 70%, 50%)",
    "Salles & équipements sportifs": 0,
    "Salles & équipements sportifsColor": "hsl(340, 70%, 50%)",
  },
  {
    Année: "2016",
    Immobilier: 140,
    ImmobilierColor: "hsl(229, 70%, 50%)",
    "Électroménager, électronique et mobilier": 28,
    "Électroménager, électronique et mobilierColor": "hsl(296, 70%, 50%)",
    Change: 0.56,
    ChangeColor: "hsl(97, 70%, 50%)",
    Glacier: 0.05,
    GlacierColor: "hsl(340, 70%, 50%)",
    "Agriculture, agroalimentaire": 9.8,
    "Agriculture, agroalimentaireColor": "hsl(340, 70%, 50%)",
    "Commerce de gros": 0,
    "Commerce de grosColor": "hsl(340, 70%, 50%)",
    "Construction, équipement": 14,
    "Construction, équipementColor": "hsl(340, 70%, 50%)",
    "Fonderie, équipement automobile": 0,
    "Fonderie, équipement automobileColor": "hsl(340, 70%, 50%)",
    "Valeurs mobilières & immobilières": 0,
    "Valeurs mobilières & immobilièresColor": "hsl(340, 70%, 50%)",
    "Salles & équipements sportifs": 0,
    "Salles & équipements sportifsColor": "hsl(340, 70%, 50%)",
  },
  {
    Année: "2017",
    Immobilier: 153.8,
    ImmobilierColor: "hsl(229, 70%, 50%)",
    "Électroménager, électronique et mobilier": 28.4,
    "Électroménager, électronique et mobilierColor": "hsl(296, 70%, 50%)",
    Change: 21.4,
    ChangeColor: "hsl(97, 70%, 50%)",
    Glacier: 0.02,
    GlacierColor: "hsl(340, 70%, 50%)",
    "Agriculture, agroalimentaire": 14.6,
    "Agriculture, agroalimentaireColor": "hsl(340, 70%, 50%)",
    "Commerce de gros": 0,
    "Commerce de grosColor": "hsl(340, 70%, 50%)",
    "Construction, équipement": 0,
    "Construction, équipementColor": "hsl(340, 70%, 50%)",
    "Fonderie, équipement automobile": 0,
    "Fonderie, équipement automobileColor": "hsl(340, 70%, 50%)",
    "Valeurs mobilières & immobilières": 0,
    "Valeurs mobilières & immobilièresColor": "hsl(340, 70%, 50%)",
    "Salles & équipements sportifs": 0.69,
    "Salles & équipements sportifsColor": "hsl(340, 70%, 50%)",
  },
  {
    Année: "2018",
    Immobilier: 14.7,
    ImmobilierColor: "hsl(229, 70%, 50%)",
    "Électroménager, électronique et mobilier": 36.8,
    "Électroménager, électronique et mobilierColor": "hsl(296, 70%, 50%)",
    Change: 0.42,
    ChangeColor: "hsl(97, 70%, 50%)",
    Glacier: 0.04,
    GlacierColor: "hsl(340, 70%, 50%)",
    "Agriculture, agroalimentaire": 16.2,
    "Agriculture, agroalimentaireColor": "hsl(340, 70%, 50%)",
    "Commerce de gros": 0,
    "Commerce de grosColor": "hsl(340, 70%, 50%)",
    "Construction, équipement": 8.8,
    "Construction, équipementColor": "hsl(340, 70%, 50%)",
    "Fonderie, équipement automobile": 0,
    "Fonderie, équipement automobileColor": "hsl(340, 70%, 50%)",
    "Valeurs mobilières & immobilières": 0,
    "Valeurs mobilières & immobilièresColor": "hsl(340, 70%, 50%)",
    "Salles & équipements sportifs": 0.54,
    "Salles & équipements sportifsColor": "hsl(340, 70%, 50%)",
  },
  {
    Année: "2019",
    Immobilier: 69,
    ImmobilierColor: "hsl(229, 70%, 50%)",
    "Électroménager, électronique et mobilier": 0,
    "Électroménager, électronique et mobilierColor": "hsl(296, 70%, 50%)",
    Change: 0.36,
    ChangeColor: "hsl(97, 70%, 50%)",
    Glacier: 0.06,
    GlacierColor: "hsl(340, 70%, 50%)",
    "Agriculture, agroalimentaire": 0,
    "Agriculture, agroalimentaireColor": "hsl(340, 70%, 50%)",
    "Commerce de gros": 0,
    "Commerce de grosColor": "hsl(340, 70%, 50%)",
    "Construction, équipement": 17,
    "Construction, équipementColor": "hsl(340, 70%, 50%)",
    "Fonderie, équipement automobile": 0,
    "Fonderie, équipement automobileColor": "hsl(340, 70%, 50%)",
    "Valeurs mobilières & immobilières": 0,
    "Valeurs mobilières & immobilièresColor": "hsl(340, 70%, 50%)",
    "Salles & équipements sportifs": 0.51,
    "Salles & équipements sportifsColor": "hsl(340, 70%, 50%)",
  },
  {
    Année: "2020",
    Immobilier: 40.5,
    ImmobilierColor: "hsl(229, 70%, 50%)",
    "Électroménager, électronique et mobilier": 61.4,
    "Électroménager, électronique et mobilierColor": "hsl(296, 70%, 50%)",
    Change: 0.07,
    ChangeColor: "hsl(97, 70%, 50%)",
    Glacier: 0,
    GlacierColor: "hsl(340, 70%, 50%)",
    "Agriculture, agroalimentaire": 15.5,
    "Agriculture, agroalimentaireColor": "hsl(340, 70%, 50%)",
    "Commerce de gros": 0,
    "Commerce de grosColor": "hsl(340, 70%, 50%)",
    "Construction, équipement": 42.5,
    "Construction, équipementColor": "hsl(340, 70%, 50%)",
    "Fonderie, équipement automobile": 0,
    "Fonderie, équipement automobileColor": "hsl(340, 70%, 50%)",
    "Valeurs mobilières & immobilières": 0,
    "Valeurs mobilières & immobilièresColor": "hsl(340, 70%, 50%)",
    "Salles & équipements sportifs": 0.03,
    "Salles & équipements sportifsColor": "hsl(340, 70%, 50%)",
  },
  {
    Année: "2021",
    Immobilier: 13.5,
    ImmobilierColor: "hsl(229, 70%, 50%)",
    "Électroménager, électronique et mobilier": 0,
    "Électroménager, électronique et mobilierColor": "hsl(296, 70%, 50%)",
    Change: 0,
    ChangeColor: "hsl(97, 70%, 50%)",
    Glacier: 0,
    GlacierColor: "hsl(340, 70%, 50%)",
    "Agriculture, agroalimentaire": 0,
    "Agriculture, agroalimentaireColor": "hsl(340, 70%, 50%)",
    "Commerce de gros": 0,
    "Commerce de grosColor": "hsl(340, 70%, 50%)",
    "Construction, équipement": 19.5,
    "Construction, équipementColor": "hsl(340, 70%, 50%)",
    "Fonderie, équipement automobile": 0,
    "Fonderie, équipement automobileColor": "hsl(340, 70%, 50%)",
    "Valeurs mobilières & immobilières": 0,
    "Valeurs mobilières & immobilièresColor": "hsl(340, 70%, 50%)",
    "Salles & équipements sportifs": 0,
    "Salles & équipements sportifsColor": "hsl(340, 70%, 50%)",
  },
];

export const StreamData = [
  {
    Immobilier: 2,
    "Électroménager, électronique": 1,
    Change: 0,
    Glacier: 0,
    "Commerce, import-export": 0,
    "Agriculture, agroalimentaire": 0,
    "Construction, équipement": 0,
    "Valeurs mobilières et immobilières": 0,
    "Salles et équipements de sport": 0,
    "Joailleries et bijouteries": 1,
    "Hébergement et restauration": 0,
    Fonderies: 0,
    "Exploitation de sources d'eau": 0,
  },

  {
    Immobilier: 3,
    "Électroménager, électronique": 3,
    Change: 0,
    Glacier: 0,
    "Commerce, import-export": 0,
    "Agriculture, agroalimentaire": 0,
    "Construction, équipement": 0,
    "Valeurs mobilières et immobilières": 0,
    "Salles et équipements de sport": 0,
    "Joailleries et bijouteries": 1,
    "Hébergement et restauration": 0,
    Fonderies: 0,
    "Exploitation de sources d'eau": 0,
  },

  {
    Immobilier: 6,
    "Électroménager, électronique": 3,
    Change: 1,
    Glacier: 0,
    "Commerce, import-export": 0,
    "Agriculture, agroalimentaire": 0,
    "Construction, équipement": 0,
    "Valeurs mobilières et immobilières": 0,
    "Salles et équipements de sport": 0,
    "Joailleries et bijouteries": 1,
    "Hébergement et restauration": 0,
    Fonderies: 0,
    "Exploitation de sources d'eau": 1,
  },

  {
    Immobilier: 6,
    "Électroménager, électronique": 4,
    Change: 1,
    Glacier: 0,
    "Commerce, import-export": 0,
    "Agriculture, agroalimentaire": 0,
    "Construction, équipement": 0,
    "Valeurs mobilières et immobilières": 1,
    "Salles et équipements de sport": 0,
    "Joailleries et bijouteries": 1,
    "Hébergement et restauration": 0,
    Fonderies: 0,
    "Exploitation de sources d'eau": 1,
  },

  {
    Immobilier: 7,
    "Électroménager, électronique": 4,
    Change: 1,
    Glacier: 0,
    "Commerce, import-export": 0,
    "Agriculture, agroalimentaire": 0,
    "Construction, équipement": 0,
    "Valeurs mobilières et immobilières": 4,
    "Salles et équipements de sport": 0,
    "Joailleries et bijouteries": 1,
    "Hébergement et restauration": 1,
    Fonderies: 0,
    "Exploitation de sources d'eau": 1,
  },

  {
    Immobilier: 8,
    "Électroménager, électronique": 4,
    Change: 1,
    Glacier: 0,
    "Commerce, import-export": 0,
    "Agriculture, agroalimentaire": 1,
    "Construction, équipement": 0,
    "Valeurs mobilières et immobilières": 4,
    "Salles et équipements de sport": 0,
    "Joailleries et bijouteries": 1,
    "Hébergement et restauration": 1,
    Fonderies: 0,
    "Exploitation de sources d'eau": 1,
  },

  {
    Immobilier: 10,
    "Électroménager, électronique": 4,
    Change: 1,
    Glacier: 0,
    "Commerce, import-export": 0,
    "Agriculture, agroalimentaire": 2,
    "Construction, équipement": 0,
    "Valeurs mobilières et immobilières": 4,
    "Salles et équipements de sport": 0,
    "Joailleries et bijouteries": 1,
    "Hébergement et restauration": 1,
    Fonderies: 0,
    "Exploitation de sources d'eau": 1,
  },

  {
    Immobilier: 11,
    "Électroménager, électronique": 5,
    Change: 1,
    Glacier: 1,
    "Commerce, import-export": 1,
    "Agriculture, agroalimentaire": 2,
    "Construction, équipement": 0,
    "Valeurs mobilières et immobilières": 5,
    "Salles et équipements de sport": 0,
    "Joailleries et bijouteries": 1,
    "Hébergement et restauration": 1,
    Fonderies: 0,
    "Exploitation de sources d'eau": 1,
  },

  {
    Immobilier: 11,
    "Électroménager, électronique": 5,
    Change: 1,
    Glacier: 1,
    "Commerce, import-export": 1,
    "Agriculture, agroalimentaire": 2,
    "Construction, équipement": 1,
    "Valeurs mobilières et immobilières": 5,
    "Salles et équipements de sport": 0,
    "Joailleries et bijouteries": 1,
    "Hébergement et restauration": 1,
    Fonderies: 0,
    "Exploitation de sources d'eau": 1,
  },

  {
    Immobilier: 11,
    "Électroménager, électronique": 5,
    Change: 1,
    Glacier: 1,
    "Commerce, import-export": 1,
    "Agriculture, agroalimentaire": 2,
    "Construction, équipement": 2,
    "Valeurs mobilières et immobilières": 5,
    "Salles et équipements de sport": 0,
    "Joailleries et bijouteries": 1,
    "Hébergement et restauration": 1,
    Fonderies: 0,
    "Exploitation de sources d'eau": 1,
  },

  {
    Immobilier: 11,
    "Électroménager, électronique": 5,
    Change: 1,
    Glacier: 1,
    "Commerce, import-export": 1,
    "Agriculture, agroalimentaire": 2,
    "Construction, équipement": 2,
    "Valeurs mobilières et immobilières": 7,
    "Salles et équipements de sport": 0,
    "Joailleries et bijouteries": 1,
    "Hébergement et restauration": 2,
    Fonderies: 1,
    "Exploitation de sources d'eau": 1,
  },

  {
    Immobilier: 14,
    "Électroménager, électronique": 5,
    Change: 1,
    Glacier: 1,
    "Commerce, import-export": 2,
    "Agriculture, agroalimentaire": 2,
    "Construction, équipement": 2,
    "Valeurs mobilières et immobilières": 7,
    "Salles et équipements de sport": 1,
    "Joailleries et bijouteries": 1,
    "Hébergement et restauration": 2,
    Fonderies: 1,
    "Exploitation de sources d'eau": 1,
  },
];

export const PieData = [
  {
    id: "Hébergement, restauration",
    label: "Hébergement, restauration",
    value: 2,
    color: "hsl(344, 70%, 50%)",
  },
  {
    id: "Commerce, import-export",
    label: "Commerce, import-export",
    value: 2,
    color: "hsl(344, 70%, 50%)",
  },
  {
    id: "Construction, équipement",
    label: "Construction, équipement",
    value: 2,
    color: "hsl(344, 70%, 50%)",
  },
  {
    id: "Agriculture, agroalimentaire",
    label: "Agriculture, agroalimentaire",
    value: 2,
    color: "hsl(344, 70%, 50%)",
  },
  {
    id: "Électroménager, électronique",
    label: "Électroménager, électronique",
    value: 5,
    color: "hsl(162, 70%, 50%)",
  },
  {
    id: "Valeurs mobilières et immobilières",
    label: "Valeurs mobilières et immobilières",
    value: 7,
    color: "hsl(344, 70%, 50%)",
  },
  {
    id: "Immobilier",
    label: "Immobilier",
    value: 14,
    color: "hsl(104, 70%, 50%)",
  },
  {
    id: "Change",
    label: "Change",
    value: 1,
    color: "hsl(291, 70%, 50%)",
  },
  {
    id: "Glacier",
    label: "Glacier",
    value: 1,
    color: "hsl(229, 70%, 50%)",
  },
  {
    id: "Fonderie, équipement automobile",
    label: "Fonderie, équipement automobile",
    value: 1,
    color: "hsl(344, 70%, 50%)",
  },
  {
    id: "Salles et équipements de sport",
    label: "Salles et équipements de sport",
    value: 1,
    color: "hsl(344, 70%, 50%)",
  },
  {
    id: "Joailleries et bijouteries",
    label: "Joailleries et bijouteries",
    value: 1,
    color: "hsl(344, 70%, 50%)",
  },
  {
    id: "Exploitation de sources d'eau",
    label: "Exploitation de sources d'eau",
    value: 1,
    color: "hsl(344, 70%, 50%)",
  },
];

export const LineData = [
  {
    id: "Immobilier",
    data: [
      {
        x: "2005",
        y: 119,
      },
      {
        x: "2006",
        y: 8169447,
      },
      {
        x: "2007",
        y: 33153283,
      },
      {
        x: "2008",
        y: 74330442,
      },
      {
        x: "2009",
        y: 2558180,
      },
      {
        x: "2010",
        y: 0,
      },
      {
        x: "2011",
        y: 1122015,
      },
      {
        x: "2012",
        y: 17082232,
      },
      {
        x: "2013",
        y: 58473374,
      },
      {
        x: "2014",
        y: 46334579,
      },
      {
        x: "2015",
        y: 41961112,
      },
      {
        x: "2016",
        y: 141208379,
      },
      {
        x: "2017",
        y: 153836719,
      },
      {
        x: "2018",
        y: 15598748,
      },
      {
        x: "2019",
        y: 77651852,
      },
      {
        x: "2020",
        y: 76645609,
      },
      {
        x: "2021",
        y: 23600288,
      },
    ],
  },
  {
    id: "Électroménager, électronique",
    data: [
      {
        x: "2005",
        y: 0,
      },
      {
        x: "2006",
        y: 0,
      },
      {
        x: "2007",
        y: 826066,
      },
      {
        x: "2008",
        y: 0,
      },
      {
        x: "2009",
        y: 7048257,
      },
      {
        x: "2010",
        y: 27904631,
      },
      {
        x: "2011",
        y: 48178226,
      },
      {
        x: "2012",
        y: 18102725,
      },
      {
        x: "2013",
        y: 41869306,
      },
      {
        x: "2014",
        y: 47891974,
      },
      {
        x: "2015",
        y: 19806733,
      },
      {
        x: "2016",
        y: 28052887,
      },
      {
        x: "2017",
        y: 28427572,
      },
      {
        x: "2018",
        y: 36858217,
      },
      {
        x: "2019",
        y: 0,
      },
      {
        x: "2020",
        y: 61492526,
      },
      {
        x: "2021",
        y: 9500,
      },
    ],
  },
  {
    id: "Change",
    data: [
      {
        x: "2005",
        y: 0,
      },
      {
        x: "2006",
        y: 0,
      },
      {
        x: "2007",
        y: 0,
      },
      {
        x: "2008",
        y: 0,
      },
      {
        x: "2009",
        y: 12010323,
      },
      {
        x: "2010",
        y: 47309525,
      },
      {
        x: "2011",
        y: 55624533,
      },
      {
        x: "2012",
        y: 44091329,
      },
      {
        x: "2013",
        y: 288977,
      },
      {
        x: "2014",
        y: 590209,
      },
      {
        x: "2015",
        y: 611301,
      },
      {
        x: "2016",
        y: 560380,
      },
      {
        x: "2017",
        y: 0,
      },
      {
        x: "2018",
        y: 424404,
      },
      {
        x: "2019",
        y: 368816,
      },
      {
        x: "2020",
        y: 72814,
      },
      {
        x: "2021",
        y: 0,
      },
    ],
  },
  {
    id: "Glacier",
    data: [
      {
        x: "2005",
        y: 0,
      },
      {
        x: "2006",
        y: 0,
      },
      {
        x: "2007",
        y: 0,
      },
      {
        x: "2008",
        y: 0,
      },
      {
        x: "2009",
        y: 0,
      },
      {
        x: "2010",
        y: 120037,
      },
      {
        x: "2011",
        y: 233752,
      },
      {
        x: "2012",
        y: 326594,
      },
      {
        x: "2013",
        y: 243635,
      },
      {
        x: "2014",
        y: 66422,
      },
      {
        x: "2015",
        y: 3028,
      },
      {
        x: "2016",
        y: 52511,
      },
      {
        x: "2017",
        y: 21429,
      },
      {
        x: "2018",
        y: 49167,
      },
      {
        x: "2019",
        y: 69506,
      },
      {
        x: "2020",
        y: 2167,
      },
      {
        x: "2021",
        y: 0,
      },
    ],
  },
  {
    id: "Agriculture & agroalimentaire",
    data: [
      {
        x: "2005",
        y: 0,
      },
      {
        x: "2006",
        y: 0,
      },
      {
        x: "2007",
        y: 0,
      },
      {
        x: "2008",
        y: 0,
      },
      {
        x: "2009",
        y: 0,
      },
      {
        x: "2010",
        y: 0,
      },
      {
        x: "2011",
        y: 0,
      },
      {
        x: "2012",
        y: 0,
      },
      {
        x: "2013",
        y: 0,
      },
      {
        x: "2014",
        y: 4265472,
      },
      {
        x: "2015",
        y: 11288664,
      },
      {
        x: "2016",
        y: 9885672,
      },
      {
        x: "2017",
        y: 14602856,
      },
      {
        x: "2018",
        y: 16213141,
      },
      {
        x: "2019",
        y: 0,
      },
      {
        x: "2020",
        y: 15514365,
      },
      {
        x: "2021",
        y: 0,
      },
    ],
  },
  {
    id: "Commerce, import-export",
    data: [
      {
        x: "2005",
        y: 0,
      },
      {
        x: "2006",
        y: 0,
      },
      {
        x: "2007",
        y: 0,
      },
      {
        x: "2008",
        y: 0,
      },
      {
        x: "2009",
        y: 0,
      },
      {
        x: "2010",
        y: 0,
      },
      {
        x: "2011",
        y: 0,
      },
      {
        x: "2012",
        y: 0,
      },
      {
        x: "2013",
        y: 0,
      },
      {
        x: "2014",
        y: 0,
      },
      {
        x: "2015",
        y: 14050,
      },
      {
        x: "2016",
        y: 4250,
      },
      {
        x: "2017",
        y: 0,
      },
      {
        x: "2018",
        y: 0,
      },
      {
        x: "2019",
        y: 0,
      },
      {
        x: "2020",
        y: null,
      },
      {
        x: "2021",
        y: 0,
      },
    ],
  },
  {
    id: "Construction, équipement",
    data: [
      {
        x: "2005",
        y: 0,
      },
      {
        x: "2006",
        y: 0,
      },
      {
        x: "2007",
        y: 0,
      },
      {
        x: "2008",
        y: 0,
      },
      {
        x: "2009",
        y: 0,
      },
      {
        x: "2010",
        y: 0,
      },
      {
        x: "2011",
        y: 0,
      },
      {
        x: "2012",
        y: 0,
      },
      {
        x: "2013",
        y: 0,
      },
      {
        x: "2014",
        y: 0,
      },
      {
        x: "2015",
        y: 7477147,
      },
      {
        x: "2016",
        y: 14002752,
      },
      {
        x: "2017",
        y: 0,
      },
      {
        x: "2018",
        y: 8841198,
      },
      {
        x: "2019",
        y: 8914115,
      },
      {
        x: "2020",
        y: 6417615,
      },
      {
        x: "2021",
        y: 9538428,
      },
    ],
  },
  {
    id: "Salles & équipements sportifs",
    data: [
      {
        x: "2005",
        y: 0,
      },
      {
        x: "2006",
        y: 0,
      },
      {
        x: "2007",
        y: 0,
      },
      {
        x: "2008",
        y: 0,
      },
      {
        x: "2009",
        y: 0,
      },
      {
        x: "2010",
        y: 0,
      },
      {
        x: "2011",
        y: 0,
      },
      {
        x: "2012",
        y: 0,
      },
      {
        x: "2013",
        y: 0,
      },
      {
        x: "2014",
        y: 0,
      },
      {
        x: "2015",
        y: 0,
      },
      {
        x: "2016",
        y: 0,
      },
      {
        x: "2017",
        y: 694157,
      },
      {
        x: "2018",
        y: 547929,
      },
      {
        x: "2019",
        y: 518262,
      },
      {
        x: "2020",
        y: 37350,
      },
      {
        x: "2021",
        y: 0,
      },
    ],
  },
  {
    id: "Valeurs mobilières et immobilières",
    data: [
      {
        x: "2005",
        y: 0,
      },
      {
        x: "2006",
        y: 0,
      },
      {
        x: "2007",
        y: 0,
      },
      {
        x: "2008",
        y: 0,
      },
      {
        x: "2009",
        y: 0,
      },
      {
        x: "2010",
        y: 0,
      },
      {
        x: "2011",
        y: 0,
      },
      {
        x: "2012",
        y: 0,
      },
      {
        x: "2013",
        y: 0,
      },
      {
        x: "2014",
        y: 0,
      },
      {
        x: "2015",
        y: 0,
      },
      {
        x: "2016",
        y: 0,
      },
      {
        x: "2017",
        y: 0,
      },
      {
        x: "2018",
        y: 0,
      },
      {
        x: "2019",
        y: 0,
      },
      {
        x: "2020",
        y: 0,
      },
      {
        x: "2021",
        y: 0,
      },
    ],
  },
  {
    id: "Joailleries, bijouteries",
    data: [
      {
        x: "2005",
        y: 0,
      },
      {
        x: "2006",
        y: 0,
      },
      {
        x: "2007",
        y: 0,
      },
      {
        x: "2008",
        y: 0,
      },
      {
        x: "2009",
        y: 0,
      },
      {
        x: "2010",
        y: 0,
      },
      {
        x: "2011",
        y: 0,
      },
      {
        x: "2012",
        y: 0,
      },
      {
        x: "2013",
        y: 0,
      },
      {
        x: "2014",
        y: 0,
      },
      {
        x: "2015",
        y: 0,
      },
      {
        x: "2016",
        y: 0,
      },
      {
        x: "2017",
        y: 0,
      },
      {
        x: "2018",
        y: 0,
      },
      {
        x: "2019",
        y: 0,
      },
      {
        x: "2020",
        y: 0,
      },
      {
        x: "2021",
        y: 0,
      },
    ],
  },
  {
    id: "Hébergement, restauration",
    data: [
      {
        x: "2005",
        y: 0,
      },
      {
        x: "2006",
        y: 0,
      },
      {
        x: "2007",
        y: 0,
      },
      {
        x: "2008",
        y: 0,
      },
      {
        x: "2009",
        y: 0,
      },
      {
        x: "2010",
        y: 0,
      },
      {
        x: "2011",
        y: 0,
      },
      {
        x: "2012",
        y: 0,
      },
      {
        x: "2013",
        y: 0,
      },
      {
        x: "2014",
        y: 0,
      },
      {
        x: "2015",
        y: 0,
      },
      {
        x: "2016",
        y: 0,
      },
      {
        x: "2017",
        y: 0,
      },
      {
        x: "2018",
        y: 0,
      },
      {
        x: "2019",
        y: 0,
      },
      {
        x: "2020",
        y: 0,
      },
      {
        x: "2021",
        y: 0,
      },
    ],
  },
  {
    id: "Exploitation de sources d'eau",
    data: [
      {
        x: "2005",
        y: 0,
      },
      {
        x: "2006",
        y: 0,
      },
      {
        x: "2007",
        y: 0,
      },
      {
        x: "2008",
        y: 0,
      },
      {
        x: "2009",
        y: 0,
      },
      {
        x: "2010",
        y: 0,
      },
      {
        x: "2011",
        y: 0,
      },
      {
        x: "2012",
        y: 0,
      },
      {
        x: "2013",
        y: 0,
      },
      {
        x: "2014",
        y: 0,
      },
      {
        x: "2015",
        y: 0,
      },
      {
        x: "2016",
        y: 0,
      },
      {
        x: "2017",
        y: 0,
      },
      {
        x: "2018",
        y: 0,
      },
      {
        x: "2019",
        y: 0,
      },
      {
        x: "2020",
        y: 0,
      },
      {
        x: "2021",
        y: 0,
      },
    ],
  },
  {
    id: "Fonderie, équipement automobile",
    data: [
      {
        x: "2005",
        y: 0,
      },
      {
        x: "2006",
        y: 0,
      },
      {
        x: "2007",
        y: 0,
      },
      {
        x: "2008",
        y: 0,
      },
      {
        x: "2009",
        y: 0,
      },
      {
        x: "2010",
        y: 0,
      },
      {
        x: "2011",
        y: 0,
      },
      {
        x: "2012",
        y: 0,
      },
      {
        x: "2013",
        y: 0,
      },
      {
        x: "2014",
        y: 0,
      },
      {
        x: "2015",
        y: 0,
      },
      {
        x: "2016",
        y: 0,
      },
      {
        x: "2017",
        y: 0,
      },
      {
        x: "2018",
        y: 0,
      },
      {
        x: "2019",
        y: 0,
      },
      {
        x: "2020",
        y: 0,
      },
      {
        x: "2021",
        y: 0,
      },
    ],
  },
];

export const LineDigitalWay = [
  {
    id: "Digital Way",
    data: [
      {
        x: "2015",
        y: 14050,
      },
      {
        x: "2016",
        y: 4250,
      },
      {
        x: "2017",
        y: null,
      },
      {
        x: "2018",
        y: null,
      },
      {
        x: "2019",
        y: null,
      },
      {
        x: "2020",
        y: null,
      },
    ],
  },
];

export const LineAttika = [
  {
    id: "Attika de Change",
    data: [
      {
        x: "2009",
        y: 12010323,
      },
      {
        x: "2010",
        y: 47309525,
      },
      {
        x: "2011",
        y: 55624533,
      },
      {
        x: "2012",
        y: 44091329,
      },
      {
        x: "2013",
        y: 288977,
      },
      {
        x: "2014",
        y: 590209,
      },
      {
        x: "2015",
        y: 611301,
      },
      {
        x: "2016",
        y: 560380,
      },
      {
        x: "2017",
        y: 0,
      },
      {
        x: "2018",
        y: 424404,
      },
      {
        x: "2019",
        y: 368816,
      },
      {
        x: "2020",
        y: 72814,
      },
    ],
  },
];

export const LineChimal = [
  {
    id: "Chimal Immobilière",
    data: [
      {
        x: "2005",
        y: 119,
      },
      {
        x: "2006",
        y: null,
      },
      {
        x: "2007",
        y: 30645041,
      },
      {
        x: "2008",
        y: 10645642,
      },
      {
        x: "2009",
        y: 2558180,
      },
      {
        x: "2010",
        y: null,
      },
      {
        x: "2011",
        y: null,
      },
      {
        x: "2012",
        y: null,
      },
      {
        x: "2013",
        y: 997788,
      },
      {
        x: "2014",
        y: 2662746,
      },
      {
        x: "2015",
        y: null,
      },
      {
        x: "2016",
        y: null,
      },
      {
        x: "2017",
        y: null,
      },
      {
        x: "2018",
        y: null,
      },
      {
        x: "2019",
        y: null,
      },
      {
        x: "2020",
        y: null,
      },
      {
        x: "2021",
        y: null,
      },
    ],
  },
];

export const LineCinar = [
  {
    id: "Cinar Habitat",
    data: [
      {
        x: "2012",
        y: 15621246,
      },
      {
        x: "2013",
        y: 54762773,
      },
      {
        x: "2014",
        y: 40163431,
      },
      {
        x: "2015",
        y: 18992326,
      },
      {
        x: "2016",
        y: 11015512,
      },
      {
        x: "2017",
        y: null,
      },
      {
        x: "2018",
        y: 7665089,
      },
      {
        x: "2019",
        y: 4593802,
      },
      {
        x: "2020",
        y: 3399999,
      },
    ],
  },
];

export const LineElCosat = [
  {
    id: "El Cosat",
    data: [
      {
        x: "2007",
        y: 826066,
      },
      {
        x: "2008",
        y: null,
      },
      {
        x: "2009",
        y: 7048257,
      },
      {
        x: "2010",
        y: 324613,
      },
      {
        x: "2011",
        y: 12936873,
      },
      {
        x: "2012",
        y: 17417130,
      },
      {
        x: "2013",
        y: 21463857,
      },
      {
        x: "2014",
        y: 26557672,
      },
      {
        x: "2015",
        y: 8768433,
      },
      {
        x: "2016",
        y: 12023021,
      },
    ],
  },
];

export const LineCostaIce = [
  {
    id: "Costa Ice",
    data: [
      {
        x: "2010",
        y: 120037,
      },
      {
        x: "2011",
        y: 233752,
      },
      {
        x: "2012",
        y: 326594,
      },
      {
        x: "2013",
        y: 243635,
      },
      {
        x: "2014",
        y: 66422,
      },
      {
        x: "2015",
        y: 3028,
      },
      {
        x: "2016",
        y: 52511,
      },
      {
        x: "2017",
        y: 21429,
      },
      {
        x: "2018",
        y: 49167,
      },
      {
        x: "2019",
        y: 69506,
      },
      {
        x: "2020",
        y: 2167,
      },
    ],
  },
];

export const SankeySallam = {
  nodes: [
    {
      id: "Sallam Chaâbani",
      nodeColor: "hsl(295, 70%, 50%)",
    },
    {
      id: "Vision Marocaine de Technologie",
      nodeColor: "hsl(106, 70%, 50%)",
    },
    {
      id: "El Cosat",
      nodeColor: "hsl(336, 70%, 50%)",
    },
    {
      id: "Immobilière Riad Al Ounss",
      nodeColor: "hsl(94, 70%, 50%)",
    },
    {
      id: "Cinar Habitat",
      nodeColor: "hsl(16, 70%, 50%)",
    },
    {
      id: "Seconad Immobilier",
      nodeColor: "hsl(47, 70%, 50%)",
    },
    {
      id: "Attika de Change",
      nodeColor: "hsl(47, 70%, 50%)",
    },
    {
      id: "Costa Ice",
      nodeColor: "hsl(47, 70%, 50%)",
    },
    {
      id: "Shandong",
      nodeColor: "hsl(47, 70%, 50%)",
    },
    {
      id: "Electro Corner",
      nodeColor: "hsl(47, 70%, 50%)",
    },
    {
      id: "Satema",
      nodeColor: "hsl(47, 70%, 50%)",
    },
    {
      id: "Manzil Founti",
      nodeColor: "hsl(47, 70%, 50%)",
    },
    {
      id: "Agroprim",
      nodeColor: "hsl(47, 70%, 50%)",
    },
    {
      id: "Commelibre",
      nodeColor: "hsl(47, 70%, 50%)",
    },
    {
      id: "Morjana Loge",
      nodeColor: "hsl(47, 70%, 50%)",
    },
    {
      id: "Gestion Energie Soutenable",
      nodeColor: "hsl(47, 70%, 50%)",
    },
    {
      id: "Trimalux",
      nodeColor: "hsl(47, 70%, 50%)",
    },
    {
      id: "Home Hotel",
      nodeColor: "hsl(47, 70%, 50%)",
    },
    {
      id: "SC Invest",
      nodeColor: "hsl(47, 70%, 50%)",
    },
    {
      id: "Digital Way",
      nodeColor: "hsl(47, 70%, 50%)",
    },
    {
      id: "Foodsnmoods Immo",
      nodeColor: "hsl(47, 70%, 50%)",
    },
    {
      id: "Gymnatex",
      nodeColor: "hsl(47, 70%, 50%)",
    },
    {
      id: "Bijouterie Al Kanz",
      nodeColor: "hsl(47, 70%, 50%)",
    },
    {
      id: "Chimal Immobilière",
      nodeColor: "hsl(47, 70%, 50%)",
    },
    {
      id: "Espace Nador",
      nodeColor: "hsl(47, 70%, 50%)",
    },
  ],
  links: [
    {
      source: "Sallam Chaâbani",
      target: "Vision Marocaine de Technologie",
      value: 12.5,
    },
    {
      source: "Sallam Chaâbani",
      target: "El Cosat",
      value: 12.5,
    },
    {
      source: "Sallam Chaâbani",
      target: "Espace Nador",
      value: 25,
    },
    {
      source: "Sallam Chaâbani",
      target: "Immobilière Riad Al Ounss",
      value: 1,
    },
    {
      source: "Sallam Chaâbani",
      target: "Cinar Habitat",
      value: 50,
    },
    {
      source: "Sallam Chaâbani",
      target: "Seconad Immobilier",
      value: 20,
    },
    {
      source: "Sallam Chaâbani",
      target: "Attika de Change",
      value: 100,
    },
    {
      source: "Sallam Chaâbani",
      target: "Costa Ice",
      value: 25,
    },
    {
      source: "Sallam Chaâbani",
      target: "Shandong",
      value: 8.33,
    },
    {
      source: "Sallam Chaâbani",
      target: "Electro Corner",
      value: 20,
    },
    {
      source: "Sallam Chaâbani",
      target: "Satema",
      value: 25,
    },
    {
      source: "Sallam Chaâbani",
      target: "Manzil Founti",
      value: 50,
    },
    {
      source: "Sallam Chaâbani",
      target: "Agroprim",
      value: 25,
    },
    {
      source: "Sallam Chaâbani",
      target: "Commelibre",
      value: 100,
    },
    {
      source: "Sallam Chaâbani",
      target: "Morjana Loge",
      value: 25,
    },
    {
      source: "Sallam Chaâbani",
      target: "Gestion Energie Soutenable",
      value: 12.5,
    },
    {
      source: "Sallam Chaâbani",
      target: "Trimalux",
      value: 10,
    },
    {
      source: "Sallam Chaâbani",
      target: "Home Hotel",
      value: 8.33,
    },
    {
      source: "Sallam Chaâbani",
      target: "SC Invest",
      value: 100,
    },
    {
      source: "Sallam Chaâbani",
      target: "Digital Way",
      value: 25,
    },
    {
      source: "Sallam Chaâbani",
      target: "Foodsnmoods Immo",
      value: 10,
    },
    {
      source: "Sallam Chaâbani",
      target: "Gymnatex",
      value: 25,
    },
    {
      source: "Sallam Chaâbani",
      target: "Bijouterie Al Kanz",
      value: 100,
    },
    {
      source: "Sallam Chaâbani",
      target: "Chimal Immobilière",
      value: 1,
    },
  ],
};

export const SankeySaid = {
  nodes: [
    {
      id: "Said Chaâbani",
      nodeColor: "hsl(336, 70%, 50%)",
    },
    {
      id: "Espace Nador",
      nodeColor: "hsl(336, 70%, 50%)",
    },
    {
      id: "Groupe Vision International",
      nodeColor: "hsl(336, 70%, 50%)",
    },
    {
      id: "El Cosat",
      nodeColor: "hsl(336, 70%, 50%)",
    },
    {
      id: "Seconad Immobilier",
      nodeColor: "hsl(47, 70%, 50%)",
    },
    {
      id: "Costa Ice",
      nodeColor: "hsl(47, 70%, 50%)",
    },
    {
      id: "Shandong",
      nodeColor: "hsl(47, 70%, 50%)",
    },
    {
      id: "Electro Corner",
      nodeColor: "hsl(47, 70%, 50%)",
    },
    {
      id: "Satema",
      nodeColor: "hsl(47, 70%, 50%)",
    },
    {
      id: "Tingis Projects",
      nodeColor: "hsl(47, 70%, 50%)",
    },
    {
      id: "Manzil Founti",
      nodeColor: "hsl(47, 70%, 50%)",
    },
    {
      id: "Agroprim",
      nodeColor: "hsl(47, 70%, 50%)",
    },
    {
      id: "Morjana Loge",
      nodeColor: "hsl(47, 70%, 50%)",
    },
    {
      id: "Vision Clim",
      nodeColor: "hsl(47, 70%, 50%)",
    },
    {
      id: "Gestion Energie Soutenable",
      nodeColor: "hsl(47, 70%, 50%)",
    },
    {
      id: "Trimalux",
      nodeColor: "hsl(47, 70%, 50%)",
    },
    {
      id: "Home Hotel",
      nodeColor: "hsl(47, 70%, 50%)",
    },
    {
      id: "Anoual Plazza",
      nodeColor: "hsl(47, 70%, 50%)",
    },
    {
      id: "Digital Way",
      nodeColor: "hsl(47, 70%, 50%)",
    },
    {
      id: "Foodsnmoods Immo",
      nodeColor: "hsl(47, 70%, 50%)",
    },
    {
      id: "Gymnatex",
      nodeColor: "hsl(47, 70%, 50%)",
    },
    {
      id: "Vision Marocaine de Technologie",
      nodeColor: "hsl(336, 70%, 50%)",
    },
  ],
  links: [
    {
      source: "Said Chaâbani",
      target: "Vision Marocaine de Technologie",
      value: 12.5,
    },
    {
      source: "Said Chaâbani",
      target: "Groupe Vision International",
      value: 50,
    },
    {
      source: "Said Chaâbani",
      target: "El Cosat",
      value: 12.5,
    },
    {
      source: "Said Chaâbani",
      target: "Seconad Immobilier",
      value: 20,
    },
    {
      source: "Said Chaâbani",
      target: "Costa Ice",
      value: 25,
    },
    {
      source: "Said Chaâbani",
      target: "Shandong",
      value: 8.33,
    },
    {
      source: "Said Chaâbani",
      target: "Electro Corner",
      value: 20,
    },
    {
      source: "Said Chaâbani",
      target: "Satema",
      value: 25,
    },
    {
      source: "Said Chaâbani",
      target: "Tingis Projects",
      value: 33,
    },
    {
      source: "Said Chaâbani",
      target: "Anoual Plazza",
      value: 25,
    },
    {
      source: "Said Chaâbani",
      target: "Manzil Founti",
      value: 50,
    },
    {
      source: "Said Chaâbani",
      target: "Espace Nador",
      value: 25,
    },
    {
      source: "Said Chaâbani",
      target: "Agroprim",
      value: 25,
    },
    {
      source: "Said Chaâbani",
      target: "Morjana Loge",
      value: 25,
    },
    {
      source: "Said Chaâbani",
      target: "Vision Clim",
      value: 50,
    },
    {
      source: "Said Chaâbani",
      target: "Gestion Energie Soutenable",
      value: 12.5,
    },
    {
      source: "Said Chaâbani",
      target: "Trimalux",
      value: 10,
    },
    {
      source: "Said Chaâbani",
      target: "Home Hotel",
      value: 8.33,
    },
    {
      source: "Said Chaâbani",
      target: "Digital Way",
      value: 25,
    },
    {
      source: "Said Chaâbani",
      target: "Foodsnmoods Immo",
      value: 10,
    },
    {
      source: "Said Chaâbani",
      target: "Gymnatex",
      value: 25,
    },
  ],
};

export const SankeyAbdallah = {
  nodes: [
    {
      id: "Abdallah Chaâbani",
      nodeColor: "hsl(336, 70%, 50%)",
    },
    {
      id: "Chimal Immobilière",
      nodeColor: "hsl(336, 70%, 50%)",
    },
    {
      id: "Espace Nador",
      nodeColor: "hsl(336, 70%, 50%)",
    },
    {
      id: "El Cosat",
      nodeColor: "hsl(336, 70%, 50%)",
    },
    {
      id: "Immobilière Riad Al Ounss",
      nodeColor: "hsl(336, 70%, 50%)",
    },
    {
      id: "Cinar Habitat",
      nodeColor: "hsl(336, 70%, 50%)",
    },
    {
      id: "Seconad Immobilier",
      nodeColor: "hsl(47, 70%, 50%)",
    },
    {
      id: "Costa Ice",
      nodeColor: "hsl(47, 70%, 50%)",
    },
    {
      id: "Shandong",
      nodeColor: "hsl(47, 70%, 50%)",
    },
    {
      id: "Electro Corner",
      nodeColor: "hsl(47, 70%, 50%)",
    },
    {
      id: "Satema",
      nodeColor: "hsl(47, 70%, 50%)",
    },
    {
      id: "Agroprim",
      nodeColor: "hsl(47, 70%, 50%)",
    },
    {
      id: "Gestion Energie Soutenable",
      nodeColor: "hsl(47, 70%, 50%)",
    },
    {
      id: "Trimalux",
      nodeColor: "hsl(47, 70%, 50%)",
    },
    {
      id: "Home Hotel",
      nodeColor: "hsl(47, 70%, 50%)",
    },
    {
      id: "Anoual Plazza",
      nodeColor: "hsl(47, 70%, 50%)",
    },
    {
      id: "Digital Way",
      nodeColor: "hsl(47, 70%, 50%)",
    },
    {
      id: "Foodsnmoods Immo",
      nodeColor: "hsl(47, 70%, 50%)",
    },
    {
      id: "Gymnatex",
      nodeColor: "hsl(47, 70%, 50%)",
    },
    {
      id: "Vision Marocaine de Technologie",
      nodeColor: "hsl(336, 70%, 50%)",
    },
    {
      id: "Histoire Loge",
      nodeColor: "hsl(336, 70%, 50%)",
    },
  ],
  links: [
    {
      source: "Abdallah Chaâbani",
      target: "Vision Marocaine de Technologie",
      value: 12.5,
    },
    {
      source: "Abdallah Chaâbani",
      target: "Chimal Immobilière",
      value: 1,
    },
    {
      source: "Abdallah Chaâbani",
      target: "El Cosat",
      value: 12.5,
    },
    {
      source: "Abdallah Chaâbani",
      target: "Immobilière Riad Al Ounss",
      value: 1,
    },
    {
      source: "Abdallah Chaâbani",
      target: "Espace Nador",
      value: 25,
    },
    {
      source: "Abdallah Chaâbani",
      target: "Seconad Immobilier",
      value: 20,
    },
    {
      source: "Abdallah Chaâbani",
      target: "Cinar Habitat",
      value: 50,
    },
    {
      source: "Abdallah Chaâbani",
      target: "Costa Ice",
      value: 25,
    },
    {
      source: "Abdallah Chaâbani",
      target: "Shandong",
      value: 8.33,
    },
    {
      source: "Abdallah Chaâbani",
      target: "Electro Corner",
      value: 20,
    },
    {
      source: "Abdallah Chaâbani",
      target: "Satema",
      value: 25,
    },
    {
      source: "Abdallah Chaâbani",
      target: "Histoire Loge",
      value: 1,
    },
    {
      source: "Abdallah Chaâbani",
      target: "Agroprim",
      value: 25,
    },
    {
      source: "Abdallah Chaâbani",
      target: "Gestion Energie Soutenable",
      value: 12.5,
    },
    {
      source: "Abdallah Chaâbani",
      target: "Trimalux",
      value: 10,
    },
    {
      source: "Abdallah Chaâbani",
      target: "Home Hotel",
      value: 8.33,
    },
    {
      source: "Abdallah Chaâbani",
      target: "Digital Way",
      value: 25,
    },
    {
      source: "Abdallah Chaâbani",
      target: "Foodsnmoods Immo",
      value: 10,
    },
    {
      source: "Abdallah Chaâbani",
      target: "Gymnatex",
      value: 25,
    },
  ],
};

export const SankeyHassan = {
  nodes: [
    {
      id: "Hassan Chaâbani",
      nodeColor: "hsl(336, 70%, 50%)",
    },
    {
      id: "El Cosat",
      nodeColor: "hsl(336, 70%, 50%)",
    },
    {
      id: "Espace Nador",
      nodeColor: "hsl(336, 70%, 50%)",
    },
    {
      id: "Seconad Immobilier",
      nodeColor: "hsl(47, 70%, 50%)",
    },
    {
      id: "Costa Ice",
      nodeColor: "hsl(47, 70%, 50%)",
    },
    {
      id: "Shandong",
      nodeColor: "hsl(47, 70%, 50%)",
    },
    {
      id: "Electro Corner",
      nodeColor: "hsl(47, 70%, 50%)",
    },
    {
      id: "Satema",
      nodeColor: "hsl(47, 70%, 50%)",
    },
    {
      id: "Agroprim",
      nodeColor: "hsl(47, 70%, 50%)",
    },
    {
      id: "Gestion Energie Soutenable",
      nodeColor: "hsl(47, 70%, 50%)",
    },
    {
      id: "Trimalux",
      nodeColor: "hsl(47, 70%, 50%)",
    },
    {
      id: "Home Hotel",
      nodeColor: "hsl(47, 70%, 50%)",
    },
    {
      id: "Digital Way",
      nodeColor: "hsl(47, 70%, 50%)",
    },
    {
      id: "Foodsnmoods Immo",
      nodeColor: "hsl(47, 70%, 50%)",
    },
    {
      id: "Gymnatex",
      nodeColor: "hsl(47, 70%, 50%)",
    },
    {
      id: "Vision Marocaine de Technologie",
      nodeColor: "hsl(336, 70%, 50%)",
    },
  ],
  links: [
    {
      source: "Hassan Chaâbani",
      target: "Vision Marocaine de Technologie",
      value: 12.5,
    },
    {
      source: "Hassan Chaâbani",
      target: "El Cosat",
      value: 12.5,
    },
    {
      source: "Hassan Chaâbani",
      target: "Espace Nador",
      value: 25,
    },
    {
      source: "Hassan Chaâbani",
      target: "Seconad Immobilier",
      value: 20,
    },
    {
      source: "Hassan Chaâbani",
      target: "Costa Ice",
      value: 25,
    },
    {
      source: "Hassan Chaâbani",
      target: "Shandong",
      value: 8.33,
    },
    {
      source: "Hassan Chaâbani",
      target: "Electro Corner",
      value: 20,
    },
    {
      source: "Hassan Chaâbani",
      target: "Satema",
      value: 25,
    },
    {
      source: "Hassan Chaâbani",
      target: "Agroprim",
      value: 25,
    },
    {
      source: "Hassan Chaâbani",
      target: "Gestion Energie Soutenable",
      value: 12.5,
    },
    {
      source: "Hassan Chaâbani",
      target: "Trimalux",
      value: 10,
    },
    {
      source: "Hassan Chaâbani",
      target: "Home Hotel",
      value: 8.33,
    },
    {
      source: "Hassan Chaâbani",
      target: "Digital Way",
      value: 25,
    },
    {
      source: "Hassan Chaâbani",
      target: "Foodsnmoods Immo",
      value: 10,
    },
    {
      source: "Hassan Chaâbani",
      target: "Gymnatex",
      value: 25,
    },
  ],
};

export const SankeyTawfik = {
  nodes: [
    {
      id: "Tawfik Soudani",
      nodeColor: "hsl(336, 70%, 50%)",
    },
    {
      id: "SCI Souben",
      nodeColor: "hsl(336, 70%, 50%)",
    },
    {
      id: "Souben Hotel",
      nodeColor: "hsl(336, 70%, 50%)",
    },
    {
      id: "Actif Holding",
      nodeColor: "hsl(336, 70%, 50%)",
    },
    {
      id: "Actif Media Pole",
      nodeColor: "hsl(336, 70%, 50%)",
    },
    {
      id: "TFK Immo",
      nodeColor: "hsl(336, 70%, 50%)",
    },
    {
      id: "Oujda City Center",
      nodeColor: "hsl(336, 70%, 50%)",
    },
    {
      id: "Promos Villas",
      nodeColor: "hsl(336, 70%, 50%)",
    },
    {
      id: "International Concassage",
      nodeColor: "hsl(336, 70%, 50%)",
    },
    {
      id: "Arrabii Proprieties",
      nodeColor: "hsl(336, 70%, 50%)",
    },
    {
      id: "W.A.T",
      nodeColor: "hsl(336, 70%, 50%)",
    },
  ],
  links: [
    {
      source: "Tawfik Soudani",
      target: "SCI Souben",
      value: 1,
    },
    {
      source: "Tawfik Soudani",
      target: "Souben Hotel",
      value: 1,
    },
    {
      source: "Tawfik Soudani",
      target: "Actif Holding",
      value: 50,
    },
    {
      source: "Tawfik Soudani",
      target: "Actif Media Pole",
      value: 1,
    },
    {
      source: "Tawfik Soudani",
      target: "Arrabii Proprieties",
      value: 50,
    },
    {
      source: "Tawfik Soudani",
      target: "TFK Immo",
      value: 100,
    },
    {
      source: "Tawfik Soudani",
      target: "Oujda City Center",
      value: 75,
    },
    {
      source: "Tawfik Soudani",
      target: "Promos Villas",
      value: 50,
    },
    {
      source: "Tawfik Soudani",
      target: "International Concassage",
      value: 1,
    },
    {
      source: "Tawfik Soudani",
      target: "W.A.T",
      value: 9.3,
    },
  ],
};

export const LineDataGroupeVision = [
  {
    id: "Groupe Vision International",
    data: [
      {
        x: "2010",
        y: 19854124,
      },
      {
        x: "2011",
        y: 16884135,
      },
      {
        x: "2012",
        y: null,
      },
      {
        x: "2013",
        y: 14380495,
      },
      {
        x: "2014",
        y: 20341343,
      },
      {
        x: "2015",
        y: 10782285,
      },
      {
        x: "2016",
        y: 15703763,
      },
      {
        x: "2017",
        y: 28419572,
      },
      {
        x: "2018",
        y: 36736534,
      },
      {
        x: "2019",
        y: null,
      },
      {
        x: "2020",
        y: 61477526,
      },
    ],
  },
];

export const LineDataSeconad = [
  {
    id: "Seconad Immobilier",
    data: [
      {
        x: "2016",
        y: 1573000,
      },
      {
        x: "2017",
        y: 8350000,
      },
      {
        x: "2018",
        y: 7086600,
      },
      {
        x: "2019",
        y: 4173000,
      },
      {
        x: "2020",
        y: 2288000,
      },
    ],
  },
];

export const LineDataAgroprim = [
  {
    id: "Agroprim",
    data: [
      {
        x: "2016",
        y: 0,
      },
      {
        x: "2017",
        y: 0,
      },
      {
        x: "2018",
        y: 0,
      },
      {
        x: "2019",
        y: 0,
      },
      {
        x: "2020",
        y: 0,
      },
    ],
  },
];

export const LineDataEspaceNador = [
  {
    id: "Espace Nador",
    data: [
      {
        x: "2010",
        y: 399353,
      },
      {
        x: "2011",
        y: 464733,
      },
      {
        x: "2012",
        y: 685595,
      },
      {
        x: "2013",
        y: 224682,
      },
      {
        x: "2014",
        y: 213392,
      },
      {
        x: "2015",
        y: 236354,
      },
      {
        x: "2016",
        y: 309933,
      },
      {
        x: "2017",
        y: null,
      },
      {
        x: "2018",
        y: 103833,
      },
    ],
  },
];

export const LineDataGymnatex = [
  {
    id: "Gymnatex",
    data: [
      {
        x: "2017",
        y: 694157,
      },
      {
        x: "2018",
        y: 547929,
      },
      {
        x: "2019",
        y: 518262,
      },
      {
        x: "2020",
        y: 37350,
      },
    ],
  },
];

export const LineDataHistoireLoge = [
  {
    id: "Histoire Logee",
    data: [
      {
        x: "2006",
        y: 8169447,
      },
      {
        x: "2007",
        y: 2508242,
      },
      {
        x: "2008",
        y: null,
      },
      {
        x: "2009",
        y: null,
      },
      {
        x: "2010",
        y: null,
      },
      {
        x: "2011",
        y: null,
      },
      {
        x: "2012",
        y: null,
      },
      {
        x: "2013",
        y: 2054783,
      },
      {
        x: "2014",
        y: 2529802,
      },
      {
        x: "2015",
        y: 3106219,
      },
      {
        x: "2016",
        y: 497679,
      },
      {
        x: "2017",
        y: 440169,
      },
      {
        x: "2018",
        y: null,
      },
      {
        x: "2019",
        y: 3332843,
      },
      {
        x: "2020",
        y: 476726,
      },
      {
        x: "2021",
        y: 1201238,
      },
    ],
  },
];

export const LineDataRiadAlOunss = [
  {
    id: "Immobilière Riad Al Ounss",
    data: [
      {
        x: "2011",
        y: 942015,
      },
      {
        x: "2012",
        y: 1460986,
      },
      {
        x: "2013",
        y: null,
      },
      {
        x: "2014",
        y: null,
      },
      {
        x: "2015",
        y: null,
      },
      {
        x: "2016",
        y: null,
      },
      {
        x: "2017",
        y: null,
      },
      {
        x: "2018",
        y: null,
      },
      {
        x: "2019",
        y: null,
      },
      {
        x: "2020",
        y: null,
      },
    ],
  },
];

export const LineDataMorjana = [
  {
    id: "Morjana Loge",
    data: [
      {
        x: "2015",
        y: 18512400,
      },
      {
        x: "2016",
        y: 127320205,
      },
      {
        x: "2017",
        y: 145046550,
      },
      {
        x: "2018",
        y: null,
      },
      {
        x: "2019",
        y: 56918660,
      },
      {
        x: "2020",
        y: 34359258,
      },
      {
        x: "2021",
        y: 12362536,
      },
    ],
  },
];

export const LineDataSatema = [
  {
    id: "Satema",
    data: [
      {
        x: "2014",
        y: 4265472,
      },
      {
        x: "2015",
        y: 11288664,
      },
      {
        x: "2016",
        y: 9885672,
      },
      {
        x: "2017",
        y: 14602856,
      },
      {
        x: "2018",
        y: 16213141,
      },
      {
        x: "2019",
        y: null,
      },
      {
        x: "2020",
        y: 15514365,
      },
    ],
  },
];

export const LineDataSpan = [
  {
    id: "Span Invest",
    data: [
      {
        x: "2008",
        y: 63684800,
      },
      {
        x: "2009",
        y: 0,
      },
      {
        x: "2010",
        y: 0,
      },
    ],
  },
];

export const LineVisionClim = [
  {
    id: "Vision Clim",
    data: [
      {
        x: "2015",
        y: 7477147,
      },
      {
        x: "2016",
        y: 14002752,
      },
      {
        x: "2017",
        y: null,
      },
      {
        x: "2018",
        y: 8841198,
      },
      {
        x: "2019",
        y: 8914115,
      },
      {
        x: "2020",
        y: 6417615,
      },
      {
        x: "2021",
        y: 9538428,
      },
    ],
  },
];

export const LineDataVisionMarocaine = [
  {
    id: "Vision Marocaine de Technologie",
    data: [
      {
        x: "2010",
        y: 7326541,
      },
      {
        x: "2011",
        y: 17892485,
      },
      {
        x: "2012",
        y: null,
      },
      {
        x: "2013",
        y: 5800272,
      },
      {
        x: "2014",
        y: 779567,
      },
      {
        x: "2015",
        y: 19661,
      },
      {
        x: "2016",
        y: 16170,
      },
      {
        x: "2017",
        y: 8000,
      },
      {
        x: "2018",
        y: 17850,
      },
      {
        x: "2019",
        y: null,
      },
      {
        x: "2020",
        y: 15000,
      },
      {
        x: "2021",
        y: 9500,
      },
    ],
  },
];

export const LineDataTFK = [
  {
    id: "TFK Immo",
    data: [
      {
        x: "2011",
        y: 180000,
      },
      {
        x: "2012",
        y: null,
      },
      {
        x: "2013",
        y: 658030,
      },
      {
        x: "2014",
        y: 978600,
      },
      {
        x: "2015",
        y: 1350167,
      },
      {
        x: "2016",
        y: 801983,
      },
      {
        x: "2017",
        y: null,
      },
      {
        x: "2018",
        y: 847059,
      },
      {
        x: "2019",
        y: 504977,
      },
    ],
  },
];

export const LineDataAnoual = [
  {
    id: "Anoual Plazza",
    data: [
      {
        x: "2019",
        y: 8128570,
      },
      {
        x: "2020",
        y: 36121626,
      },
      {
        x: "2021",
        y: 10036514,
      },
    ],
  },
];

export const RadialArrabii = [
  {
    id: "Actuel",
    data: [
      {
        x: "Abdelwahad Benchrif",
        y: 50,
      },
      {
        x: "Tawfik Soudani",
        y: 50,
      },
    ],
  },
];

export const RadialWAT = [
  {
    id: "01/2015",
    data: [
      {
        x: "Abdelwahad Benchrif",
        y: 25,
      },
      {
        x: "Tawfik Soudani",
        y: 75,
      },
    ],
  },
  {
    id: "03/2015",
    data: [
      {
        x: "Abdelwahad Benchrif",
        y: 7,
      },
      {
        x: "Tawfik Soudani",
        y: 93,
      },
    ],
  },
];

export const RadialInternationalConcassage = [
  {
    id: "2009",
    data: [
      {
        x: "Actif Holding",
        y: 98,
      },
      {
        x: "Abdelwahad Benchrif",
        y: 1,
      },
      {
        x: "Tawfik Soudani",
        y: 1,
      },
    ],
  },
  {
    id: "Actuel",
    data: [
      {
        x: "Abdelwahad Benchrif",
        y: 100,
      },
    ],
  },
];

export const RadialOujdaCity = [
  {
    id: "Actuel",
    data: [
      {
        x: "Tawfik Soudani",
        y: 75,
      },
      {
        x: "Abdelwahad Benchrif",
        y: 25,
      },
    ],
  },
];

export const RadialTFK = [
  {
    id: "Actuel",
    data: [
      {
        x: "Tawfik Soudani",
        y: 100,
      },
    ],
  },
];

export const RadialActifMedia = [
  {
    id: "Actuel",
    data: [
      {
        x: "Abdelwahad Benchrif",
        y: 1,
      },
      {
        x: "Tawfik Soudani",
        y: 1,
      },
      {
        x: "Actif Holding",
        y: 98,
      },
    ],
  },
];

export const RadialSoubenHotel = [
  {
    id: "Actuel",
    data: [
      {
        x: "Abdelwahad Benchrif",
        y: 1,
      },
      {
        x: "Tawfik Soudani",
        y: 1,
      },
      {
        x: "Actif Holding",
        y: 98,
      },
    ],
  },
];

export const RadialWatherLife = [
  {
    id: "2008",
    data: [
      {
        x: "Abdelwahad Benchrif",
        y: 100,
      },
    ],
  },
  {
    id: "2009",
    data: [
      {
        x: "Actif Holding",
        y: 100,
      },
    ],
  },
  {
    id: "2012",
    data: [
      {
        x: "Abdelwahad Benchrif",
        y: 50,
      },
      {
        x: "Actif Holding",
        y: 50,
      },
    ],
  },
];

export const RadialActifHolding = [
  {
    id: "2008",
    data: [
      {
        x: "Tawfik Soudani",
        y: 50,
      },
      {
        x: "Abdelwahad Benchrif",
        y: 50,
      },
    ],
  },
  {
    id: "Actuel",
    data: [
      {
        x: "Tawfik Soudani",
        y: 100,
      },
    ],
  },
];

export const RadialVisionMarocaine = [
  {
    id: "Actuel",
    data: [
      {
        x: "Sallam Chaâbani",
        y: 12.5,
      },
      {
        x: "Abdallah Chaâbani",
        y: 12.5,
      },
      {
        x: "Hassan Chaâbani",
        y: 12.5,
      },
      {
        x: "Said Chaâbani",
        y: 12.5,
      },
      {
        x: "Ahmed Bentaleb",
        y: 25,
      },
      {
        x: "Mohamed Hajouji",
        y: 25,
      },
    ],
  },
];

export const RadialPromosVillas = [
  {
    id: "2007",
    data: [
      {
        x: "Tawfik Soudani",
        y: 50,
      },
      {
        x: "Abdelwahad Benchrif",
        y: 50,
      },
    ],
  },
  {
    id: "2009",
    data: [
      {
        x: "Actif Holding",
        y: 100,
      },
    ],
  },
  {
    id: "Actuel",
    data: [
      {
        x: "Actif Holding",
        y: 50,
      },
      {
        x: "Abdelwahad Benchrif",
        y: 50,
      },
    ],
  },
];

export const RadialVisionClim = [
  {
    id: "2013",
    data: [
      {
        x: "MY HD Media FZ-LLC SARL",
        y: 51,
      },
      {
        x: "HD ELECTRONIC TECHNOLOGIE SARL",
        y: 49,
      },
    ],
  },
  {
    id: "2015",
    data: [
      {
        x: "Said Chaâbani",
        y: 50,
      },
      {
        x: "Actionnaire non identifié",
        y: 50,
      },
    ],
  },
  {
    id: "Actuel",
    data: [
      {
        x: "Ahmed Bentaleb",
        y: 50,
      },
      {
        x: "Actionnaire non identifié",
        y: 50,
      },
    ],
  },
];

export const RadialTrimalux = [
  {
    id: "Actuel",
    data: [
      {
        x: "Youssef Tafasca",
        y: 30,
      },
      {
        x: "Jaouad Sadiki",
        y: 30,
      },
      {
        x: "Sallam Chaâbani",
        y: 10,
      },
      {
        x: "Said Chaâbani",
        y: 10,
      },
      {
        x: "Abdallah Chaâbani",
        y: 10,
      },
      {
        x: "Hassan Chaâbani",
        y: 10,
      },
    ],
  },
];

export const RadialTingis = [
  {
    id: "2011",
    data: [
      {
        x: "El Ouali Charif",
        y: 100,
      },
    ],
  },
  {
    id: "Actuel",
    data: [
      {
        x: "Said Chaâbani",
        y: 33,
      },
      {
        x: "Mohamed Charif",
        y: 67,
      },
    ],
  },
];

export const RadialSpan = [
  {
    id: "2017 (Dissolution)",
    data: [
      {
        x: "Said Chaâbani",
        y: 50,
      },
      {
        x: "Mohamed Charif",
        y: 50,
      },
    ],
  },
];

export const RadialShandong = [
  {
    id: "2014",
    data: [
      {
        x: "Youssef Tafasca",
        y: 50,
      },
      {
        x: "Abdessadik Daoudi",
        y: 50,
      },
    ],
  },
  {
    id: "2015",
    data: [
      {
        x: "Youssef Tafasca",
        y: 33,
      },
      {
        x: "Héritiers Daoudi",
        y: 33,
      },
      {
        x: "Sallam Chaâbani",
        y: 8.5,
      },
      {
        x: "Said Chaâbani",
        y: 8.5,
      },
      {
        x: "Abdallah Chaâbani",
        y: 8.5,
      },
      {
        x: "Hassan Chaâbani",
        y: 8.5,
      },
    ],
  },
  {
    id: "Actuel",
    data: [
      {
        x: "Youssef Tafasca",
        y: 50,
      },
      {
        x: "Héritiers Daoudi",
        y: 50,
      },
    ],
  },
];

export const RadialSCInvest = [
  {
    id: "Actuel",
    data: [
      {
        x: "Sallam Chaâbani",
        y: 100,
      },
    ],
  },
];

export const RadialSatema = [
  {
    id: "2015",
    data: [
      {
        x: "Sallam Chaâbani",
        y: 25,
      },
      {
        x: "Said Chaâbani",
        y: 25,
      },
      {
        x: "Abdallah Chaâbani",
        y: 25,
      },
      {
        x: "Hassan Chaâbani",
        y: 25,
      },
      {
        x: "Mohamed El Yahiaoui",
        y: 0,
      },
      {
        x: "Salah Quella",
        y: 0,
      },
      {
        x: "Jamal-Eddine Boujaraf",
        y: 0,
      },
    ],
  },
  {
    id: "2016",
    data: [
      {
        x: "Mohamed El Yahiaoui",
        y: 100,
      },
    ],
  },
  {
    id: "2019",
    data: [
      {
        x: "Salah Quella",
        y: 100,
      },
    ],
  },
  {
    id: "Actuel",
    data: [
      {
        x: "Jamal-Eddine Boujaraf",
        y: 100,
      },
    ],
  },
];

export const RadialMorjana = [
  {
    id: "2015",
    data: [
      {
        x: "Sallam Chaâbani",
        y: 25,
      },
      {
        x: "Said Chaâbani",
        y: 25,
      },
      {
        x: "Mohamed Nair",
        y: 25,
      },
      {
        x: "Hicham Nair",
        y: 25,
      },
    ],
  },
  {
    id: "2016",
    data: [
      {
        x: "Jamal Nair",
        y: 50,
      },
      {
        x: "Mohamed El Yahiaoui",
        y: 50,
      },
    ],
  },
  {
    id: "2017",
    data: [
      {
        x: "Khadija Nair",
        y: 50,
      },
      {
        x: "Mohamed El Yahiaoui",
        y: 50,
      },
    ],
  },
  {
    id: "2019",
    data: [
      {
        x: "Khadija Nair",
        y: 50,
      },
      {
        x: "Jamal Hamdan",
        y: 50,
      },
    ],
  },
  {
    id: "2020",
    data: [
      {
        x: "Khadija Nair",
        y: 50,
      },
      {
        x: "Soukaina Maarouf",
        y: 50,
      },
    ],
  },
];

export const RadialManzilFounti = [
  {
    id: "2016 (Dissolution)",
    data: [
      {
        x: "Sallam Chaâbani",
        y: 50,
      },
      {
        x: "Abdallah Chaâbani",
        y: 50,
      },
    ],
  },
];

export const RadialHomeHotel = [
  {
    id: "Actuel",
    data: [
      {
        x: "Youssef Tafasca",
        y: 33,
      },
      {
        x: "Zouhair Daoudi",
        y: 10,
      },
      {
        x: "Khadija Daoudi",
        y: 5,
      },
      {
        x: "Salma Daoudi",
        y: 5,
      },
      {
        x: "Amina Daoudi",
        y: 5,
      },
      {
        x: "Bia Agounad",
        y: 6,
      },
      {
        x: "Fouzia Agounad",
        y: 4,
      },
      {
        x: "Sallam Chaâbani",
        y: 8,
      },
      {
        x: "Abdallah Chaâbani",
        y: 8,
      },
      {
        x: "Hassan Chaâbani",
        y: 8,
      },
      {
        x: "Said Chaâbani",
        y: 8,
      },
    ],
  },
];

export const RadialHistoireLoge = [
  {
    id: "Actuel",
    data: [
      {
        x: "Abdallah Chaâbani",
        y: 1,
      },
      {
        x: "Ahmed Kouam",
        y: 1,
      },
      {
        x: "Jamal Nair",
        y: 1,
      },
      {
        x: "Mustapha Sakhi",
        y: 1,
      },
    ],
  },
];

export const RadialGymnatex = [
  {
    id: "2016",
    data: [
      {
        x: "Sallam Chaâbani",
        y: 25,
      },
      {
        x: "Said Chaâbani",
        y: 25,
      },
      {
        x: "Abdallah Chaâbani",
        y: 25,
      },
      {
        x: "Hassan Chaâbani",
        y: 25,
      },
    ],
  },
  {
    id: "Actuel",
    data: [
      {
        x: "Ismail Sakkoumi",
        y: 100,
      },
    ],
  },
];

export const RadialGestionEnergie = [
  {
    id: "Actuel",
    data: [
      {
        x: "Sallam Chaabani",
        y: 12.5,
      },
      {
        x: "Abdallah Chaabani",
        y: 12.5,
      },
      {
        x: "Said Chaabani",
        y: 12.5,
      },
      {
        x: "Hassan Chaabani",
        y: 12.5,
      },
      {
        x: "Oscar Fernandez Martin",
        y: 12.5,
      },
      {
        x: "Juan Jose Fernandez Martin",
        y: 12.5,
      },
      {
        x: "Juan Francisco Gomez Lorenzo",
        y: 12.5,
      },
      {
        x: "Mohamed Karroum",
        y: 12.5,
      },
    ],
  },
];

export const RadialEspaceNador = [
  {
    id: "Actuel",
    data: [
      {
        x: "Sallam Chaabani",
        y: 25,
      },
      {
        x: "Abdallah Chaabani",
        y: 25,
      },
      {
        x: "Said Chaabani",
        y: 25,
      },
      {
        x: "Hassan Chaabani",
        y: 25,
      },
    ],
  },
];

export const RadialElectroCorner = [
  {
    id: "2017 (Dissolution)",
    data: [
      {
        x: "Sallam Chaabani",
        y: 20,
      },
      {
        x: "Abdallah Chaabani",
        y: 20,
      },
      {
        x: "Said Chaabani",
        y: 20,
      },
      {
        x: "Hassan Chaabani",
        y: 20,
      },
      {
        x: "Hamid Yaacoubi",
        y: 20,
      },
    ],
  },
];

export const RadialElCosat = [
  {
    id: "2016",
    data: [
      {
        x: "Sallam Chaabani",
        y: 12.5,
      },
      {
        x: "Abdallah Chaabani",
        y: 12.5,
      },
      {
        x: "Said Chaabani",
        y: 12.5,
      },
      {
        x: "Hassan Chaabani",
        y: 12.5,
      },
      {
        x: "Ahmed Bentaleb",
        y: 25,
      },
      {
        x: "Mohamed Hajouji",
        y: 25,
      },
    ],
  },
  {
    id: "Actuel",
    data: [
      {
        x: "Sidi Mohamed Salem Chkirid",
        y: 100,
      },
    ],
  },
];

export const RadialGroupeVision = [
  {
    id: "2006",
    data: [
      {
        x: "El Bachir Hajouji",
        y: 50,
      },
      {
        x: "Aicha Hajouji",
        y: 50,
      },
    ],
  },
  {
    id: "2008",
    data: [
      {
        x: "El Bachir Hajouji",
        y: 50,
      },
      {
        x: "Touria Hajouji",
        y: 50,
      },
    ],
  },
  {
    id: "2010",
    data: [
      {
        x: "El Bachir Hajouji",
        y: 50,
      },
      {
        x: "Said Chaabani",
        y: 50,
      },
    ],
  },
  {
    id: "2016",
    data: [
      {
        x: "El Bachir Hajouji",
        y: 50,
      },
      {
        x: "Mouloud Hajouji",
        y: 50,
      },
    ],
  },
  {
    id: "2022",
    data: [
      {
        x: "M'barek Sayidati",
        y: 100,
      },
    ],
  },
];

export const RadialFoodsnMoods = [
  {
    id: "2016",
    data: [
      {
        x: "Sallam Chaabani",
        y: 10,
      },
      {
        x: "Abdallah Chaabani",
        y: 10,
      },
      {
        x: "Said Chaabani",
        y: 10,
      },
      {
        x: "Hassan Chaabani",
        y: 10,
      },
      {
        x: "Ismail Aissaoui",
        y: 20,
      },
      {
        x: "Ahmed Bentaleb",
        y: 20,
      },
      {
        x: "Mohamed Hajouji",
        y: 20,
      },
    ],
  },
  {
    id: "Actuel",
    data: [
      {
        x: "Sallam Chaabani",
        y: 12.5,
      },
      {
        x: "Abdallah Chaabani",
        y: 12.5,
      },
      {
        x: "Said Chaabani",
        y: 12.5,
      },
      {
        x: "Hassan Chaabani",
        y: 12.5,
      },
      {
        x: "Ahmed Bentaleb",
        y: 25,
      },
      {
        x: "Mohamed Hajouji",
        y: 25,
      },
    ],
  },
];

export const RadialDigitalWay = [
  {
    id: "Actuel",
    data: [
      {
        x: "Sallam Chaabani",
        y: 25,
      },
      {
        x: "Abdallah Chaabani",
        y: 25,
      },
      {
        x: "Said Chaabani",
        y: 25,
      },
      {
        x: "Hassan Chaabani",
        y: 25,
      },
    ],
  },
];

export const RadialAlKanz = [
  {
    id: "Actuel",
    data: [
      {
        x: "Sallam Chaabani",
        y: 100,
      },
    ],
  },
];

export const RadialCommelibre = [
  {
    id: "Actuel",
    data: [
      {
        x: "Sallam Chaabani",
        y: 100,
      },
    ],
  },
];

export const RadialCostaIce = [
  {
    id: "2016",
    data: [
      {
        x: "Sallam Chaabani",
        y: 25,
      },
      {
        x: "Abdallah Chaabani",
        y: 25,
      },
      {
        x: "Said Chaabani",
        y: 25,
      },
      {
        x: "Hassan Chaabani",
        y: 25,
      },
      {
        x: "Mimoun El Bachiri",
        y: 0,
      },
    ],
  },
  {
    id: "Actuel",
    data: [
      {
        x: "Mimoun El Bachiri",
        y: 100,
      },
    ],
  },
];

export const RadialSeconad = [
  {
    id: "2007",
    data: [
      {
        x: "Mohamed Chaâbani",
        y: 20,
      },
      {
        x: "Sallam Chaâbani",
        y: 20,
      },
      {
        x: "Said Chaâbani",
        y: 20,
      },
      {
        x: "Abdallah Chaâbani",
        y: 20,
      },
      {
        x: "Hassan Chaâbani",
        y: 20,
      },
      {
        x: "Lahbib Kaaouich",
        y: 0,
      },
      {
        x: "Mimoun El Bachiri",
        y: 0,
      },
    ],
  },
  {
    id: "Actuel",
    data: [
      {
        x: "Mohamed Chaâbani",
        y: 20,
      },
      {
        x: "Sallam Chaâbani",
        y: 0,
      },
      {
        x: "Said Chaâbani",
        y: 0,
      },
      {
        x: "Abdallah Chaâbani",
        y: 0,
      },
      {
        x: "Hassan Chaâbani",
        y: 0,
      },
      {
        x: "Lahbib Kaaouich",
        y: 40,
      },
      {
        x: "Mimoun El Bachiri",
        y: 40,
      },
    ],
  },
];

export const RadialCinar = [
  {
    id: "2016",
    data: [
      {
        x: "Sallam Chaabani",
        y: 50,
      },
      {
        x: "Abdallah Chaabani",
        y: 50,
      },
      {
        x: "Mohamed El Yahiaoui",
        y: 0,
      },
    ],
  },
  {
    id: "Actuel",
    data: [
      {
        x: "Mohamed El Yahiaoui",
        y: 100,
      },
    ],
  },
];

export const RadialAgroprim = [
  {
    id: "Actuel",
    data: [
      {
        x: "Sallam Chaâbani",
        y: 25,
      },
      {
        x: "Said Chaâbani",
        y: 25,
      },
      {
        x: "Abdallah Chaâbani",
        y: 25,
      },
      {
        x: "Hassan Chaâbani",
        y: 25,
      },
    ],
  },
];

export const RadialAnoual = [
  {
    id: "02/2016",
    data: [
      {
        x: "Jamal Nair",
        y: 50,
      },
      {
        x: "Abdallah Chaâbani",
        y: 25,
      },
      {
        x: "Said Chaâbani",
        y: 25,
      },
    ],
  },
  {
    id: "11/2016",
    data: [
      {
        x: "Jamal Nair",
        y: 75,
      },
      {
        x: "Miloud Nair",
        y: 25,
      },
    ],
  },
  {
    id: "2017",
    data: [
      {
        x: "Jamal Nair",
        y: 50,
      },
      {
        x: "Miloud Nair",
        y: 25,
      },
      {
        x: "Khadija Nair",
        y: 25,
      },
    ],
  },
  {
    id: "Actuel",
    data: [
      {
        x: "Jamal Nair",
        y: 50,
      },
      {
        x: "Miloud Nair",
        y: 25,
      },
      {
        x: "M'hamed Nair",
        y: 25,
      },
    ],
  },
];

export const RadialAttika = [
  {
    id: "Actuel",
    data: [
      {
        x: "Sallam Chaâbani",
        y: 100,
      },
    ],
  },
];

export const RadialChimal = [
  {
    id: "Actuel",
    data: [
      {
        x: "Sallam Chaabani",
        y: 1,
      },
      {
        x: "Abdallah Chaabani",
        y: 1,
      },
      {
        x: "Mohamed Tawfik Hussein",
        y: 1,
      },
    ],
  },
];

export const SankeyArrabii = {
  nodes: [
    { id: "Abdelwahad Benchrif (2009-Actuel)" },
    { id: "Tawfik Soudani (2009-Actuel)" },
    { id: "Arrabii Proprieties" },
  ],
  links: [
    {
      source: "Abdelwahad Benchrif (2009-Actuel)",
      target: "Arrabii Proprieties",
      value: 50,
    },
    {
      source: "Tawfik Soudani (2009-Actuel)",
      target: "Arrabii Proprieties",
      value: 50,
    },
  ],
};

export const SankeyWAT = {
  nodes: [
    { id: "Abdelwahad Benchrif (2015-Actuel)" },
    { id: "Tawfik Soudani (2015-Actuel)" },
    { id: "W.A.T" },
  ],
  links: [
    {
      source: "Abdelwahad Benchrif (2015-Actuel)",
      target: "Tawfik Soudani (2015-Actuel)",
      value: 18,
    },
    {
      source: "Abdelwahad Benchrif (2015-Actuel)",
      target: "W.A.T",
      value: 7,
    },
    {
      source: "Tawfik Soudani (2015-Actuel)",
      target: "W.A.T",
      value: 93,
    },
  ],
};

export const SankeyInternationalConcassage = {
  nodes: [
    { id: "Abdelwahad Benchrif (2009-Actuel)" },
    { id: "Tawfik Soudani (2009-2012)" },
    { id: "Actif Holding (2009-2012)" },
    { id: "International Concassage" },
  ],
  links: [
    {
      source: "Actif Holding (2009-2012)",
      target: "Abdelwahad Benchrif (2009-Actuel)",
      value: 98,
    },
    {
      source: "Tawfik Soudani (2009-2012)",
      target: "Abdelwahad Benchrif (2009-Actuel)",
      value: 1,
    },
    {
      source: "Abdelwahad Benchrif (2009-Actuel)",
      target: "International Concassage",
      value: 100,
    },
  ],
};

export const SankeyPromosVillas = {
  nodes: [
    { id: "Abdelwahad Benchrif (2007-Actuel)" },
    { id: "Abdelwahad Benchrif (2007-Actuel.)" },
    { id: "Tawfik Soudani (2007-2012)" },
    { id: "Actif Holding (2009-Actuel)" },
    { id: "Promos Villas" },
  ],
  links: [
    {
      source: "Abdelwahad Benchrif (2007-Actuel.)",
      target: "Actif Holding (2009-Actuel)",
      value: 50,
    },
    {
      source: "Tawfik Soudani (2007-2012)",
      target: "Actif Holding (2009-Actuel)",
      value: 50,
    },
    {
      source: "Actif Holding (2009-Actuel)",
      target: "Abdelwahad Benchrif (2007-Actuel)",
      value: 50,
    },
    {
      source: "Abdelwahad Benchrif (2007-Actuel)",
      target: "Promos Villas",
      value: 50,
    },
    {
      source: "Actif Holding (2009-Actuel)",
      target: "Promos Villas",
      value: 50,
    },
  ],
};

export const SankeyOujdaCity = {
  nodes: [
    {
      id: "Tawfik Soudani (2012-Actuel)",
    },
    {
      id: "Abdelwahad Benchrif (2012-Actuel)",
    },
    {
      id: "Oujda City Center",
    },
  ],
  links: [
    {
      source: "Tawfik Soudani (2012-Actuel)",
      target: "Oujda City Center",
      value: 75,
    },
    {
      source: "Abdelwahad Benchrif (2012-Actuel)",
      target: "Oujda City Center",
      value: 25,
    },
  ],
};

export const SankeyTFK = {
  nodes: [
    {
      id: "Tawfik Soudani (2010-Actuel)",
    },
    {
      id: "TFK Immo",
    },
  ],
  links: [
    {
      source: "Tawfik Soudani (2010-Actuel)",
      target: "TFK Immo",
      value: 100,
    },
  ],
};

export const SankeyActifMedia = {
  nodes: [
    {
      id: "Actif Holding (2009-Actuel)",
    },
    {
      id: "Abdelwahad Benchrif (2009-Actuel)",
    },
    {
      id: "Tawfik Soudani (2009-Actuel)",
    },
    {
      id: "Actif Media Pole",
    },
  ],
  links: [
    {
      source: "Actif Holding (2009-Actuel)",
      target: "Actif Media Pole",
      value: 98,
    },
    {
      source: "Abdelwahad Benchrif (2009-Actuel)",
      target: "Actif Media Pole",
      value: 1,
    },
    {
      source: "Tawfik Soudani (2009-Actuel)",
      target: "Actif Media Pole",
      value: 1,
    },
  ],
};

export const SankeySoubenHotel = {
  nodes: [
    {
      id: "Actif Holding (2009-Actuel)",
    },
    {
      id: "Abdelwahad Benchrif (2009-Actuel)",
    },
    {
      id: "Tawfik Soudani (2009-Actuel)",
    },
    {
      id: "Souben Hotel",
    },
  ],
  links: [
    {
      source: "Actif Holding (2009-Actuel)",
      target: "Souben Hotel",
      value: 98,
    },
    {
      source: "Abdelwahad Benchrif (2009-Actuel)",
      target: "Souben Hotel",
      value: 1,
    },
    {
      source: "Tawfik Soudani (2009-Actuel)",
      target: "Souben Hotel",
      value: 1,
    },
  ],
};

export const SankeyWatherLife = {
  nodes: [
    {
      id: "Actif Holding (2009-2012)",
    },
    {
      id: "Abdelwahad Benchrif (2008-Actuel)",
    },
    {
      id: "Abdelwahad Benchrif (2008-Actuel.)",
    },
    {
      id: "Wather Life",
    },
  ],
  links: [
    {
      source: "Abdelwahad Benchrif (2008-Actuel)",
      target: "Actif Holding (2009-2012)",
      value: 100,
    },
    {
      source: "Actif Holding (2009-2012)",
      target: "Abdelwahad Benchrif (2008-Actuel.)",
      value: 50,
    },
    {
      source: "Abdelwahad Benchrif (2008-Actuel.)",
      target: "Wather Life",
      value: 50,
    },
    {
      source: "Actif Holding (2009-2012)",
      target: "Wather Life",
      value: 50,
    },
  ],
};

export const SankeyActifHolding = {
  nodes: [
    {
      id: "Tawfik Soudani (2008-Actuel)",
    },
    {
      id: "Abdelwahad Benchrif (2008-2012)",
    },
    {
      id: "Actif Holding",
    },
  ],
  links: [
    {
      source: "Abdelwahad Benchrif (2008-2012)",
      target: "Tawfik Soudani (2008-Actuel)",
      value: 50,
    },
    {
      source: "Tawfik Soudani (2008-Actuel)",
      target: "Actif Holding",
      value: 100,
    },
  ],
};

export const SankeyVisionMarocaine = {
  nodes: [
    {
      id: "Sallam Chaâbani (2005-Actuel)",
    },
    {
      id: "Said Chaâbani (2005-Actuel)",
    },
    {
      id: "Abdallah Chaâbani (2005-Actuel)",
    },
    {
      id: "Hassan Chaâbani (2005-Actuel)",
    },
    {
      id: "Ahmed Bentaleb (2005-Actuel)",
    },
    {
      id: "Mohamed Hajouji (2005-Actuel)",
    },
    {
      id: "Vision Marocaine de Technologie",
    },
  ],
  links: [
    {
      source: "Sallam Chaâbani (2005-Actuel)",
      target: "Vision Marocaine de Technologie",
      value: 12.5,
    },
    {
      source: "Said Chaâbani (2005-Actuel)",
      target: "Vision Marocaine de Technologie",
      value: 12.5,
    },
    {
      source: "Hassan Chaâbani (2005-Actuel)",
      target: "Vision Marocaine de Technologie",
      value: 12.5,
    },
    {
      source: "Abdallah Chaâbani (2005-Actuel)",
      target: "Vision Marocaine de Technologie",
      value: 12.5,
    },
    {
      source: "Ahmed Bentaleb (2005-Actuel)",
      target: "Vision Marocaine de Technologie",
      value: 25,
    },
    {
      source: "Mohamed Hajouji (2005-Actuel)",
      target: "Vision Marocaine de Technologie",
      value: 25,
    },
  ],
};

export const SankeyVisionClim = {
  nodes: [
    {
      id: "MY HD Media FZ-LLC SARL (2013)",
    },
    {
      id: "HD ELECTRONIC TECHNOLOGIE SARL (2013)",
    },
    {
      id: "Said Chaâbani (?-2016)",
    },
    {
      id: "Ahmed Bentaleb (?-Actuel)",
    },
    {
      id: "Actionnaire non identifié (?-Actuel)",
    },
    {
      id: "Vision Clim",
    },
  ],
  links: [
    {
      source: "MY HD Media FZ-LLC SARL (2013)",
      target: "Said Chaâbani (?-2016)",
      value: 51,
    },
    {
      source: "MY HD Media FZ-LLC SARL (2013)",
      target: "Ahmed Bentaleb (?-Actuel)",
      value: 51,
    },
    {
      source: "HD ELECTRONIC TECHNOLOGIE SARL (2013)",
      target: "Ahmed Bentaleb (?-Actuel)",
      value: 49,
    },
    {
      source: "HD ELECTRONIC TECHNOLOGIE SARL (2013)",
      target: "Said Chaâbani (?-2016)",
      value: 49,
    },
    {
      source: "Said Chaâbani (?-2016)",
      target: "Ahmed Bentaleb (?-Actuel)",
      value: 50,
    },
    {
      source: "Ahmed Bentaleb (?-Actuel)",
      target: "Vision Clim",
      value: 50,
    },
    {
      source: "Actionnaire non identifié (?-Actuel)",
      target: "Vision Clim",
      value: 50,
    },
  ],
};

export const SankeyTingis = {
  nodes: [
    {
      id: "Said Chaâbani (2014-Actuel)",
    },
    {
      id: "Mohamed Charif (2014-Actuel)",
    },
    {
      id: "El Ouali Charif (2011-2014)",
    },
    {
      id: "Tingis Projects",
    },
  ],
  links: [
    {
      source: "Said Chaâbani (2014-Actuel)",
      target: "Tingis Projects",
      value: 33,
    },
    {
      source: "Mohamed Charif (2014-Actuel)",
      target: "Tingis Projects",
      value: 67,
    },
    {
      source: "El Ouali Charif (2011-2014)",
      target: "Mohamed Charif (2014-Actuel)",
      value: 67,
    },
    {
      source: "El Ouali Charif (2011-2014)",
      target: "Said Chaâbani (2014-Actuel)",
      value: 33,
    },
  ],
};

export const SankeySpan = {
  nodes: [
    {
      id: "Said Chaâbani (2006-2017)",
    },
    {
      id: "Mohamed Charif (2006-2017)",
    },
    {
      id: "Span Invest",
    },
  ],
  links: [
    {
      source: "Said Chaâbani (2006-2017)",
      target: "Span Invest",
      value: 50,
    },
    {
      source: "Mohamed Charif (2006-2017)",
      target: "Span Invest",
      value: 50,
    },
  ],
};

export const SankeyShandong = {
  nodes: [
    {
      id: "Youssef Tafasca (2010-Actuel)",
    },
    {
      id: "Youssef Tafasca (2010-Actuel.)",
    },
    {
      id: "Abdessadik Daoudi (2010-2014)",
    },
    {
      id: "Héritiers Daoudi (2015-Actuel)",
    },
    {
      id: "Héritiers Daoudi (2015-Actuel.)",
    },
    {
      id: "Sallam Chaâbani (2015-2016)",
    },
    {
      id: "Abdallah Chaâbani (2015-2016)",
    },
    {
      id: "Hassan Chaâbani (2015-2016)",
    },
    {
      id: "Said Chaâbani (2015-2016)",
    },
    {
      id: "Shandong",
    },
  ],
  links: [
    {
      source: "Youssef Tafasca (2010-Actuel)",
      target: "Shandong",
      value: 50,
    },
    {
      source: "Youssef Tafasca (2010-Actuel)",
      target: "Shandong",
      value: 50,
    },
    {
      source: "Abdessadik Daoudi (2010-2014)",
      target: "Héritiers Daoudi (2015-Actuel.)",
      value: 50,
    },
    {
      source: "Héritiers Daoudi (2015-Actuel.)",
      target: "Sallam Chaâbani (2015-2016)",
      value: 4.25,
    },
    {
      source: "Héritiers Daoudi (2015-Actuel.)",
      target: "Said Chaâbani (2015-2016)",
      value: 4.25,
    },
    {
      source: "Héritiers Daoudi (2015-Actuel.)",
      target: "Abdallah Chaâbani (2015-2016)",
      value: 4.25,
    },
    {
      source: "Héritiers Daoudi (2015-Actuel.)",
      target: "Hassan Chaâbani (2015-2016)",
      value: 4.25,
    },
    {
      source: "Youssef Tafasca (2010-Actuel.)",
      target: "Sallam Chaâbani (2015-2016)",
      value: 4.25,
    },
    {
      source: "Youssef Tafasca (2010-Actuel.)",
      target: "Said Chaâbani (2015-2016)",
      value: 4.25,
    },
    {
      source: "Youssef Tafasca (2010-Actuel.)",
      target: "Abdallah Chaâbani (2015-2016)",
      value: 4.25,
    },
    {
      source: "Youssef Tafasca (2010-Actuel.)",
      target: "Hassan Chaâbani (2015-2016)",
      value: 4.25,
    },
    {
      source: "Youssef Tafasca (2010-Actuel.)",
      target: "Youssef Tafasca (2010-Actuel)",
      value: 33,
    },
    {
      source: "Héritiers Daoudi (2015-Actuel.)",
      target: "Héritiers Daoudi (2015-Actuel)",
      value: 33,
    },
    {
      source: "Héritiers Daoudi (2015-Actuel)",
      target: "Shandong",
      value: 50,
    },
    {
      source: "Sallam Chaâbani (2015-2016)",
      target: "Héritiers Daoudi (2015-Actuel)",
      value: 4.25,
    },
    {
      source: "Sallam Chaâbani (2015-2016)",
      target: "Youssef Tafasca (2010-Actuel)",
      value: 4.25,
    },
    {
      source: "Said Chaâbani (2015-2016)",
      target: "Héritiers Daoudi (2015-Actuel)",
      value: 4.25,
    },
    {
      source: "Said Chaâbani (2015-2016)",
      target: "Youssef Tafasca (2010-Actuel)",
      value: 4.25,
    },
    {
      source: "Hassan Chaâbani (2015-2016)",
      target: "Héritiers Daoudi (2015-Actuel)",
      value: 4.25,
    },
    {
      source: "Hassan Chaâbani (2015-2016)",
      target: "Youssef Tafasca (2010-Actuel)",
      value: 4.25,
    },
    {
      source: "Abdallah Chaâbani (2015-2016)",
      target: "Héritiers Daoudi (2015-Actuel)",
      value: 4.25,
    },
    {
      source: "Abdallah Chaâbani (2015-2016)",
      target: "Youssef Tafasca (2010-Actuel)",
      value: 4.25,
    },
  ],
};

export const SankeyTrimalux = {
  nodes: [
    {
      id: "Youssef Tafasca (2015-Actuel)",
    },
    {
      id: "Jaouad Sadiki (2015-Actuel)",
    },
    {
      id: "Sallam Chaâbani (2015-Actuel)",
    },
    {
      id: "Said Chaâbani (2015-Actuel)",
    },
    {
      id: "Abdallah Chaâbani (2015-Actuel)",
    },
    {
      id: "Hassan Chaâbani (2015-Actuel)",
    },
    {
      id: "Trimalux",
    },
  ],
  links: [
    {
      source: "Youssef Tafasca (2015-Actuel)",
      target: "Trimalux",
      value: 30,
    },
    {
      source: "Jaouad Sadiki (2015-Actuel)",
      target: "Trimalux",
      value: 30,
    },
    {
      source: "Sallam Chaâbani (2015-Actuel)",
      target: "Trimalux",
      value: 10,
    },
    {
      source: "Said Chaâbani (2015-Actuel)",
      target: "Trimalux",
      value: 10,
    },
    {
      source: "Abdallah Chaâbani (2015-Actuel)",
      target: "Trimalux",
      value: 10,
    },
    {
      source: "Hassan Chaâbani (2015-Actuel)",
      target: "Trimalux",
      value: 10,
    },
  ],
};

export const SankeySatema = {
  nodes: [
    {
      id: "Sallam Chaâbani (2010-2016)",
    },
    {
      id: "Said Chaâbani (2010-2016)",
    },
    {
      id: "Abdallah Chaâbani (2010-2016)",
    },
    {
      id: "Hassan Chaâbani (2010-2016)",
    },
    {
      id: "Mohamed El Yahiaoui (2016-2019)",
    },
    {
      id: "Salah Quella (09/2019-12/2019)",
    },
    {
      id: "Jamal-Eddine Boujaraf (2019-Actuel)",
    },
    {
      id: "Satema",
    },
  ],
  links: [
    {
      source: "Sallam Chaâbani (2010-2016)",
      target: "Mohamed El Yahiaoui (2016-2019)",
      value: 25,
    },
    {
      source: "Said Chaâbani (2010-2016)",
      target: "Mohamed El Yahiaoui (2016-2019)",
      value: 25,
    },
    {
      source: "Abdallah Chaâbani (2010-2016)",
      target: "Mohamed El Yahiaoui (2016-2019)",
      value: 25,
    },
    {
      source: "Hassan Chaâbani (2010-2016)",
      target: "Mohamed El Yahiaoui (2016-2019)",
      value: 25,
    },
    {
      source: "Mohamed El Yahiaoui (2016-2019)",
      target: "Salah Quella (09/2019-12/2019)",
      value: 100,
    },
    {
      source: "Salah Quella (09/2019-12/2019)",
      target: "Jamal-Eddine Boujaraf (2019-Actuel)",
      value: 100,
    },
    {
      source: "Jamal-Eddine Boujaraf (2019-Actuel)",
      target: "Satema",
      value: 100,
    },
  ],
};

export const SankeySCInvest = {
  nodes: [
    {
      id: "Sallam Chaâbani (2016-Actuel)",
    },
    {
      id: "SC Invest",
    },
  ],
  links: [
    {
      source: "Sallam Chaâbani (2016-Actuel)",
      target: "SC Invest",
      value: 100,
    },
  ],
};

export const SankeyMorjana = {
  nodes: [
    {
      id: "Sallam Chaâbani (2012-2016)",
    },
    {
      id: "Said Chaâbani (2012-2016)",
    },
    {
      id: "Jamal Nair (2012-2017)",
    },
    {
      id: "Mohamed Nair (06/2016-12/2016)",
    },
    {
      id: "Hicham Nair (06/2016-12/2016)",
    },
    {
      id: "Khadija Nair (2017-Actuel)",
    },
    {
      id: "Jamal Hamdan (2019-2020)",
    },
    {
      id: "Mohamed El Yahiaoui (2016-2019)",
    },
    {
      id: "Soukaina Maarouf (2020-Actuel)",
    },
    {
      id: "Morjana Loge",
    },
  ],
  links: [
    {
      source: "Sallam Chaâbani (2012-2016)",
      target: "Mohamed El Yahiaoui (2016-2019)",
      value: 25,
    },
    {
      source: "Said Chaâbani (2012-2016)",
      target: "Mohamed El Yahiaoui (2016-2019)",
      value: 25,
    },
    {
      source: "Jamal Nair (2012-2017)",
      target: "Khadija Nair (2017-Actuel)",
      value: 50,
    },
    {
      source: "Mohamed Nair (06/2016-12/2016)",
      target: "Jamal Nair (2012-2017)",
      value: 25,
    },
    {
      source: "Hicham Nair (06/2016-12/2016)",
      target: "Jamal Nair (2012-2017)",
      value: 25,
    },
    {
      source: "Khadija Nair (2017-Actuel)",
      target: "Morjana Loge",
      value: 50,
    },
    {
      source: "Jamal Hamdan (2019-2020)",
      target: "Soukaina Maarouf (2020-Actuel)",
      value: 50,
    },
    {
      source: "Mohamed El Yahiaoui (2016-2019)",
      target: "Jamal Hamdan (2019-2020)",
      value: 50,
    },
    {
      source: "Soukaina Maarouf (2020-Actuel)",
      target: "Morjana Loge",
      value: 50,
    },
  ],
};

export const SankeyManzilFounti = {
  nodes: [
    {
      id: "Sallam Chaâbani (2011 - 2016)",
    },
    {
      id: "Abdallah Chaâbani (2011 - 2016)",
    },
    {
      id: "Manzil Founti",
    },
  ],
  links: [
    {
      source: "Sallam Chaâbani (2011 - 2016)",
      target: "Manzil Founti",
      value: 50,
    },
    {
      source: "Abdallah Chaâbani (2011 - 2016)",
      target: "Manzil Founti",
      value: 50,
    },
  ],
};

export const SankeyRiadAlOunss = {
  nodes: [
    {
      id: "Sallam Chaâbani (2006 ? - ?)",
    },
    {
      id: "Abdallah Chaâbani (2006 ? - ?)",
    },
    {
      id: "Jamal Nair (2006 ? - ?)",
    },
    {
      id: "Ahmed Kouam (2007-2021)",
    },
    {
      id: "Mustapha Sakhi (2007 ? - ?)",
    },
    {
      id: "Immobilière Riad Al Ounss",
    },
  ],
  links: [
    {
      source: "Abdallah Chaâbani (2006 ? - ?)",
      target: "Mustapha Sakhi (2007 ? - ?)",
      value: 15,
    },
    {
      source: "Abdallah Chaâbani (2006 ? - ?)",
      target: "Ahmed Kouam (2007-2021)",
      value: 10,
    },
    {
      source: "Jamal Nair (2006 ? - ?)",
      target: "Ahmed Kouam (2007-2021)",
      value: 5,
    },
    {
      source: "Ahmed Kouam (2007-2021)",
      target: "Immobilière Riad Al Ounss",
      value: 15,
    },
    {
      source: "Mustapha Sakhi (2007 ? - ?)",
      target: "Immobilière Riad Al Ounss",
      value: 15,
    },
    {
      source: "Sallam Chaâbani (2006 ? - ?)",
      target: "Immobilière Riad Al Ounss",
      value: null,
    },
  ],
};

export const SankeyHomeHotel = {
  nodes: [
    {
      id: "Youssef Tafasca (2015-Actuel)",
    },
    {
      id: "Zouhair Daoudi (2015-Actuel)",
    },
    {
      id: "Khadija Daoudi (2015-Actuel)",
    },
    {
      id: "Salma Daoudi (2015-Actuel)",
    },
    {
      id: "Amina Daoudi (2015-Actuel)",
    },
    {
      id: "Bia Agounad (2015-Actuel)",
    },
    {
      id: "Fouzia Agounad (2015-Actuel)",
    },
    {
      id: "Sallam Chaâbani (2015-Actuel)",
    },
    {
      id: "Abdallah Chaâbani (2015-Actuel)",
    },
    {
      id: "Hassan Chaâbani (2015-Actuel)",
    },
    {
      id: "Said Chaâbani (2015-Actuel)",
    },
    {
      id: "Home Hotel",
    },
  ],
  links: [
    {
      source: "Youssef Tafasca (2015-Actuel)",
      target: "Home Hotel",
      value: 33,
    },
    {
      source: "Zouhair Daoudi (2015-Actuel)",
      target: "Home Hotel",
      value: 10,
    },
    {
      source: "Khadija Daoudi (2015-Actuel)",
      target: "Home Hotel",
      value: 5,
    },
    {
      source: "Salma Daoudi (2015-Actuel)",
      target: "Home Hotel",
      value: 5,
    },
    {
      source: "Amina Daoudi (2015-Actuel)",
      target: "Home Hotel",
      value: 5,
    },
    {
      source: "Bia Agounad (2015-Actuel)",
      target: "Home Hotel",
      value: 6,
    },
    {
      source: "Fouzia Agounad (2015-Actuel)",
      target: "Home Hotel",
      value: 4,
    },
    {
      source: "Sallam Chaâbani (2015-Actuel)",
      target: "Home Hotel",
      value: 8,
    },
    {
      source: "Abdallah Chaâbani (2015-Actuel)",
      target: "Home Hotel",
      value: 8,
    },
    {
      source: "Hassan Chaâbani (2015-Actuel)",
      target: "Home Hotel",
      value: 8,
    },
    {
      source: "Said Chaâbani (2015-Actuel)",
      target: "Home Hotel",
      value: 8,
    },
  ],
};

export const SankeyHistoireLoge = {
  nodes: [
    {
      id: "Abdallah Chaâbani (2005-Actuel)",
    },
    {
      id: "Ahmed Kouam (2005-Actuel)",
    },
    {
      id: "Jamal Nair (2005-Actuel)",
    },
    {
      id: "Mustapha Sakhi (2005-Actuel)",
    },
    {
      id: "Histoire Loge",
    },
  ],
  links: [
    {
      source: "Abdallah Chaâbani (2005-Actuel)",
      target: "Histoire Loge",
      value: 1,
    },
    {
      source: "Ahmed Kouam (2005-Actuel)",
      target: "Histoire Loge",
      value: 1,
    },
    {
      source: "Jamal Nair (2005-Actuel)",
      target: "Histoire Loge",
      value: 1,
    },
    {
      source: "Mustapha Sakhi (2005-Actuel)",
      target: "Histoire Loge",
      value: 1,
    },
  ],
};

export const SankeyGymnatex = {
  nodes: [
    {
      id: "Sallam Chaâbani (2016-2017)",
    },
    {
      id: "Abdallah Chaâbani (2016-2017)",
    },
    {
      id: "Hassan Chaâbani (2016-2017)",
    },
    {
      id: "Said Chaâbani (2016-2017)",
    },
    {
      id: "Ismail Sakkoumi (2017-Actuel)",
    },
    {
      id: "Gymnatex",
    },
  ],
  links: [
    {
      source: "Sallam Chaâbani (2016-2017)",
      target: "Ismail Sakkoumi (2017-Actuel)",
      value: 25,
    },
    {
      source: "Abdallah Chaâbani (2016-2017)",
      target: "Ismail Sakkoumi (2017-Actuel)",
      value: 25,
    },
    {
      source: "Hassan Chaâbani (2016-2017)",
      target: "Ismail Sakkoumi (2017-Actuel)",
      value: 25,
    },
    {
      source: "Said Chaâbani (2016-2017)",
      target: "Ismail Sakkoumi (2017-Actuel)",
      value: 25,
    },
    {
      source: "Ismail Sakkoumi (2017-Actuel)",
      target: "Gymnatex",
      value: 100,
    },
  ],
};

export const SankeyGroupeVision = {
  nodes: [
    {
      id: "Aicha Hajouji (2006-2008)",
    },
    {
      id: "El Bachir Hajouji (2006-2022)",
    },
    {
      id: "Touria Hajouji (2008-2010)",
    },
    {
      id: "Said Chaâbani (2010-2016)",
    },
    {
      id: "Mouloud Hajouji (2016-2022)",
    },
    {
      id: "M'barek Sayidati (2022-Actuel)",
    },
    {
      id: "Groupe Vision International",
    },
  ],
  links: [
    {
      source: "M'barek Sayidati (2022-Actuel)",
      target: "Groupe Vision International",
      value: 100,
    },
    {
      source: "El Bachir Hajouji (2006-2022)",
      target: "M'barek Sayidati (2022-Actuel)",
      value: 50,
    },
    {
      source: "Mouloud Hajouji (2016-2022)",
      target: "M'barek Sayidati (2022-Actuel)",
      value: 50,
    },
    {
      source: "Said Chaâbani (2010-2016)",
      target: "Mouloud Hajouji (2016-2022)",
      value: 50,
    },
    {
      source: "Touria Hajouji (2008-2010)",
      target: "Said Chaâbani (2010-2016)",
      value: 50,
    },
    {
      source: "Aicha Hajouji (2006-2008)",
      target: "Touria Hajouji (2008-2010)",
      value: 50,
    },
  ],
};

export const SankeyGestionEnergie = {
  nodes: [
    {
      id: "Sallam Chaâbani (2014-Actuel)",
    },
    {
      id: "Abdallah Chaâbani (2014-Actuel)",
    },
    {
      id: "Hassan Chaâbani (2014-Actuel)",
    },
    {
      id: "Said Chaâbani (2014-Actuel)",
    },
    {
      id: "Mohamed Karroum (2014-Actuel)",
    },
    {
      id: "Oscar Fernandez Martin (2014-Actuel)",
    },
    {
      id: "Juan Jose Fernandez Martin (2014-Actuel)",
    },
    {
      id: "Juan Francisco Gomez Lorenzo (2014-Actuel)",
    },
    {
      id: "Gestion Energie Soutenable",
    },
  ],
  links: [
    {
      source: "Sallam Chaâbani (2014-Actuel)",
      target: "Gestion Energie Soutenable",
      value: 12.5,
    },
    {
      source: "Abdallah Chaâbani (2014-Actuel)",
      target: "Gestion Energie Soutenable",
      value: 12.5,
    },
    {
      source: "Hassan Chaâbani (2014-Actuel)",
      target: "Gestion Energie Soutenable",
      value: 12.5,
    },
    {
      source: "Said Chaâbani (2014-Actuel)",
      target: "Gestion Energie Soutenable",
      value: 12.5,
    },
    {
      source: "Mohamed Karroum (2014-Actuel)",
      target: "Gestion Energie Soutenable",
      value: 12.5,
    },
    {
      source: "Oscar Fernandez Martin (2014-Actuel)",
      target: "Gestion Energie Soutenable",
      value: 12.5,
    },
    {
      source: "Juan Jose Fernandez Martin (2014-Actuel)",
      target: "Gestion Energie Soutenable",
      value: 12.5,
    },
    {
      source: "Juan Francisco Gomez Lorenzo (2014-Actuel)",
      target: "Gestion Energie Soutenable",
      value: 12.5,
    },
  ],
};

export const SankeyFoodsnMoods = {
  nodes: [
    {
      id: "Sallam Chaâbani (2016-Actuel)",
    },
    {
      id: "Abdallah Chaâbani (2016-Actuel)",
    },
    {
      id: "Hassan Chaâbani (2016-Actuel)",
    },
    {
      id: "Said Chaâbani (2016-Actuel)",
    },
    {
      id: "Mohamed Hajouji (2016-Actuel)",
    },
    {
      id: "Ahmed Bentaleb (2016-Actuel)",
    },
    {
      id: "Ismail Aissaoui (2016-2017)",
    },
    {
      id: "FoodsnMoods Immo",
    },
  ],
  links: [
    {
      source: "Ismail Aissaoui (2016-2017)",
      target: "Sallam Chaâbani (2016-Actuel)",
      value: 2.5,
    },
    {
      source: "Ismail Aissaoui (2016-2017)",
      target: "Abdallah Chaâbani (2016-Actuel)",
      value: 2.5,
    },
    {
      source: "Ismail Aissaoui (2016-2017)",
      target: "Said Chaâbani (2016-Actuel)",
      value: 2.5,
    },
    {
      source: "Ismail Aissaoui (2016-2017)",
      target: "Hassan Chaâbani (2016-Actuel)",
      value: 2.5,
    },
    {
      source: "Ismail Aissaoui (2016-2017)",
      target: "Mohamed Hajouji (2016-Actuel)",
      value: 5,
    },
    {
      source: "Ismail Aissaoui (2016-2017)",
      target: "Ahmed Bentaleb (2016-Actuel)",
      value: 5,
    },
    {
      source: "Sallam Chaâbani (2016-Actuel)",
      target: "FoodsnMoods Immo",
      value: 12.5,
    },
    {
      source: "Abdallah Chaâbani (2016-Actuel)",
      target: "FoodsnMoods Immo",
      value: 12.5,
    },
    {
      source: "Hassan Chaâbani (2016-Actuel)",
      target: "FoodsnMoods Immo",
      value: 12.5,
    },
    {
      source: "Said Chaâbani (2016-Actuel)",
      target: "FoodsnMoods Immo",
      value: 12.5,
    },
    {
      source: "Ahmed Bentaleb (2016-Actuel)",
      target: "FoodsnMoods Immo",
      value: 25,
    },
    {
      source: "Mohamed Hajouji (2016-Actuel)",
      target: "FoodsnMoods Immo",
      value: 25,
    },
  ],
};

export const SankeyEspaceNador = {
  nodes: [
    {
      id: "Sallam Chaâbani (2008-Actuel)",
    },
    {
      id: "Abdallah Chaâbani (2008-Actuel)",
    },
    {
      id: "Hassan Chaâbani (2008-Actuel)",
    },
    {
      id: "Said Chaâbani (2008-Actuel)",
    },
    {
      id: "Espace Nador",
    },
  ],
  links: [
    {
      source: "Sallam Chaâbani (2008-Actuel)",
      target: "Espace Nador",
      value: 25,
    },
    {
      source: "Abdallah Chaâbani (2008-Actuel)",
      target: "Espace Nador",
      value: 25,
    },
    {
      source: "Hassan Chaâbani (2008-Actuel)",
      target: "Espace Nador",
      value: 25,
    },
    {
      source: "Said Chaâbani (2008-Actuel)",
      target: "Espace Nador",
      value: 25,
    },
  ],
};

export const SankeyElectroCorner = {
  nodes: [
    {
      id: "Sallam Chaâbani (2010-2017)",
    },
    {
      id: "Abdallah Chaâbani (2010-2017)",
    },
    {
      id: "Hassan Chaâbani (2010-2017)",
    },
    {
      id: "Said Chaâbani (2010-2017)",
    },
    {
      id: "Hamid Yaacoubi (2010-2017)",
    },
    {
      id: "Electro Corner",
    },
  ],
  links: [
    {
      source: "Sallam Chaâbani (2010-2017)",
      target: "Electro Corner",
      value: 12.5,
    },
    {
      source: "Abdallah Chaâbani (2010-2017)",
      target: "Electro Corner",
      value: 12.5,
    },
    {
      source: "Hassan Chaâbani (2010-2017)",
      target: "Electro Corner",
      value: 12.5,
    },
    {
      source: "Said Chaâbani (2010-2017)",
      target: "Electro Corner",
      value: 12.5,
    },
    {
      source: "Hamid Yaacoubi (2010-2017)",
      target: "Electro Corner",
      value: 25,
    },
  ],
};

export const SankeyElCosat = {
  nodes: [
    {
      id: "Sallam Chaâbani (2010-2017)",
    },
    {
      id: "Abdallah Chaâbani (2010-2017)",
    },
    {
      id: "Hassan Chaâbani (2010-2017)",
    },
    {
      id: "Said Chaâbani (2010-2017)",
    },
    {
      id: "Ahmed Bentaleb (2010-2017)",
    },
    {
      id: "Mohamed Hajouji (2010-2017)",
    },
    {
      id: "Sidi Mohamed Salem Chkirid (2017-Actuel)",
    },
    {
      id: "El Cosat",
    },
  ],
  links: [
    {
      source: "Sallam Chaâbani (2010-2017)",
      target: "Sidi Mohamed Salem Chkirid (2017-Actuel)",
      value: 12.5,
    },
    {
      source: "Abdallah Chaâbani (2010-2017)",
      target: "Sidi Mohamed Salem Chkirid (2017-Actuel)",
      value: 12.5,
    },
    {
      source: "Hassan Chaâbani (2010-2017)",
      target: "Sidi Mohamed Salem Chkirid (2017-Actuel)",
      value: 12.5,
    },
    {
      source: "Said Chaâbani (2010-2017)",
      target: "Sidi Mohamed Salem Chkirid (2017-Actuel)",
      value: 12.5,
    },
    {
      source: "Ahmed Bentaleb (2010-2017)",
      target: "Sidi Mohamed Salem Chkirid (2017-Actuel)",
      value: 25,
    },
    {
      source: "Mohamed Hajouji (2010-2017)",
      target: "Sidi Mohamed Salem Chkirid (2017-Actuel)",
      value: 25,
    },
    {
      source: "Sidi Mohamed Salem Chkirid (2017-Actuel)",
      target: "El Cosat",
      value: 100,
    },
  ],
};

export const SankeyDigitalWay = {
  nodes: [
    {
      id: "Sallam Chaâbani",
    },
    {
      id: "Abdallah Chaâbani",
    },
    {
      id: "Hassan Chaâbani",
    },
    {
      id: "Said Chaâbani",
    },
    {
      id: "Digital Way",
    },
  ],
  links: [
    {
      source: "Sallam Chaâbani",
      target: "Digital Way",
      value: 25,
    },
    {
      source: "Abdallah Chaâbani",
      target: "Digital Way",
      value: 25,
    },
    {
      source: "Hassan Chaâbani",
      target: "Digital Way",
      value: 25,
    },
    {
      source: "Said Chaâbani",
      target: "Digital Way",
      value: 25,
    },
  ],
};

export const SankeyCostaIce = {
  nodes: [
    {
      id: "Sallam Chaâbani",
    },
    {
      id: "Abdallah Chaâbani",
    },
    {
      id: "Hassan Chaâbani",
    },
    {
      id: "Said Chaâbani",
    },
    {
      id: "Mimoun El Bachiri",
    },
    {
      id: "Costa Ice",
    },
  ],
  links: [
    {
      source: "Sallam Chaâbani",
      target: "Mimoun El Bachiri",
      value: 25,
    },
    {
      source: "Abdallah Chaâbani",
      target: "Mimoun El Bachiri",
      value: 25,
    },
    {
      source: "Hassan Chaâbani",
      target: "Mimoun El Bachiri",
      value: 25,
    },
    {
      source: "Said Chaâbani",
      target: "Mimoun El Bachiri",
      value: 25,
    },
    {
      source: "Mimoun El Bachiri",
      target: "Costa Ice",
      value: 100,
    },
  ],
};

export const SankeyCommelibre = {
  nodes: [
    {
      id: "Sallam Chaâbani",
    },
    {
      id: "Commelibre",
    },
  ],
  links: [
    {
      source: "Sallam Chaâbani",
      target: "Commelibre",
      value: 100,
    },
  ],
};

export const SankeyCinar = {
  nodes: [
    {
      id: "Sallam Chaâbani",
    },
    {
      id: "Abdallah Chaâbani",
    },
    {
      id: "Mohamed El Yahiaoui",
    },
    {
      id: "Cinar Habitat",
    },
  ],
  links: [
    {
      source: "Sallam Chaâbani",
      target: "Mohamed El Yahiaoui",
      value: 50,
    },
    {
      source: "Abdallah Chaâbani",
      target: "Mohamed El Yahiaoui",
      value: 50,
    },
    {
      source: "Mohamed El Yahiaoui",
      target: "Cinar Habitat",
      value: 100,
    },
  ],
};

export const SankeyChimal = {
  nodes: [
    {
      id: "Sallam Chaâbani",
    },
    {
      id: "Abdallah Chaâbani",
    },
    {
      id: "Mohamed Tawfik Hussein",
    },
    {
      id: "Chimal Immobilière",
    },
  ],
  links: [
    {
      source: "Sallam Chaâbani",
      target: "Chimal Immobilière",
      value: 1,
    },
    {
      source: "Abdallah Chaâbani",
      target: "Chimal Immobilière",
      value: 1,
    },
    {
      source: "Mohamed Tawfik Hussein",
      target: "Chimal Immobilière",
      value: 1,
    },
  ],
};

export const SankeyAlKanz = {
  nodes: [
    {
      id: "Sallam Chaâbani",
    },
    {
      id: "Bijouterie Al Kanz",
    },
  ],
  links: [
    {
      source: "Sallam Chaâbani",
      target: "Bijouterie Al Kanz",
      value: 100,
    },
  ],
};

export const SankeySeconad = {
  nodes: [
    {
      id: "Mohamed Chaâbani (2007-Actuel)",
    },
    {
      id: "Sallam Chaâbani (2007-2016)",
    },
    {
      id: "Said Chaâbani (2007-2016)",
    },
    {
      id: "Hassan Chaâbani (2007-2016)",
    },
    {
      id: "Abdallah Chaâbani (2007-2016)",
    },
    {
      id: "Lahbib Kaaouich (2016-Actuel)",
    },
    {
      id: "Mimoun El Bachiri (2016-Actuel)",
    },
    {
      id: "Seconad Immobilier",
    },
  ],
  links: [
    {
      source: "Sallam Chaâbani (2007-2016)",
      target: "Lahbib Kaaouich (2016-Actuel)",
      value: 10,
    },
    {
      source: "Sallam Chaâbani (2007-2016)",
      target: "Mimoun El Bachiri (2016-Actuel)",
      value: 10,
    },
    {
      source: "Said Chaâbani (2007-2016)",
      target: "Lahbib Kaaouich (2016-Actuel)",
      value: 10,
    },
    {
      source: "Said Chaâbani (2007-2016)",
      target: "Mimoun El Bachiri (2016-Actuel)",
      value: 10,
    },
    {
      source: "Hassan Chaâbani (2007-2016)",
      target: "Lahbib Kaaouich (2016-Actuel)",
      value: 10,
    },
    {
      source: "Hassan Chaâbani (2007-2016)",
      target: "Mimoun El Bachiri (2016-Actuel)",
      value: 10,
    },
    {
      source: "Abdallah Chaâbani (2007-2016)",
      target: "Lahbib Kaaouich (2016-Actuel)",
      value: 10,
    },
    {
      source: "Abdallah Chaâbani (2007-2016)",
      target: "Mimoun El Bachiri (2016-Actuel)",
      value: 10,
    },
    {
      source: "Mohamed Chaâbani (2007-Actuel)",
      target: "Seconad Immobilier",
      value: 20,
    },
    {
      source: "Mimoun El Bachiri (2016-Actuel)",
      target: "Seconad Immobilier",
      value: 40,
    },
    {
      source: "Lahbib Kaaouich (2016-Actuel)",
      target: "Seconad Immobilier",
      value: 40,
    },
  ],
};

export const SankeyAttika = {
  nodes: [
    {
      id: "Sallam Chaâbani",
    },
    {
      id: "Attika de Change",
    },
  ],
  links: [
    {
      source: "Sallam Chaâbani",
      target: "Attika de Change",
      value: 100,
    },
  ],
};

export const SankeyAgroprim = {
  nodes: [
    {
      id: "Sallam Chaâbani",
    },
    {
      id: "Said Chaâbani",
    },
    {
      id: "Hassan Chaâbani",
    },
    {
      id: "Abdallah Chaâbani",
    },
    {
      id: "Agroprim",
    },
  ],
  links: [
    {
      source: "Sallam Chaâbani",
      target: "Agroprim",
      value: 25,
    },
    {
      source: "Abdallah Chaâbani",
      target: "Agroprim",
      value: 25,
    },
    {
      source: "Said Chaâbani",
      target: "Agroprim",
      value: 25,
    },
    {
      source: "Hassan Chaâbani",
      target: "Agroprim",
      value: 25,
    },
  ],
};
export const SankeyAnoual = {
  nodes: [
    {
      id: "Said Chaâbani (02/2016-11/2016)",
    },
    {
      id: "Abdallah Chaâbani (02/2016-11/2016)",
    },
    {
      id: "Jamal Nair (02/2016-Actuel)",
    },
    {
      id: "Miloud Nair (11/2016-Actuel)",
    },
    {
      id: "M'hamed Nair (2022-Actuel)",
    },
    {
      id: "Khadija Nair (2017-2021)",
    },
    {
      id: "Anoual Plazza",
    },
  ],
  links: [
    {
      source: "Said Chaâbani (02/2016-11/2016)",
      target: "Jamal Nair (02/2016-Actuel)",
      value: 25,
    },
    {
      source: "Abdallah Chaâbani (02/2016-11/2016)",
      target: "Miloud Nair (11/2016-Actuel)",
      value: 25,
    },
    {
      source: "Jamal Nair (02/2016-Actuel)",
      target: "Khadija Nair (2017-2021)",
      value: 25,
    },
    {
      source: "Khadija Nair (2017-2021)",
      target: "M'hamed Nair (2022-Actuel)",
      value: 25,
    },
    {
      source: "Jamal Nair (02/2016-Actuel)",
      target: "Anoual Plazza",
      value: 50,
    },
    {
      source: "Miloud Nair (11/2016-Actuel)",
      target: "Anoual Plazza",
      value: 25,
    },
    {
      source: "M'hamed Nair (2022-Actuel)",
      target: "Anoual Plazza",
      value: 25,
    },
  ],
};

export const SearchData = [
  {
    title: "Sallam Chaâbani",
    link: "./Sallam",
  },
  {
    title: "Abdallah Chaâbani",
    link: "./Abdallah",
  },
  {
    title: "Hassan Chaâbani",
    link: "./Hassan",
  },
  {
    title: "Said Chaâbani",
    link: "./Said",
  },
  {
    title: "Tawfik Soudani",
    link: "./Tawfik",
  },
  {
    title: "Actif Holding",
    link: "./actifholding",
  },
  {
    title: "Actif Media",
    link: "./actifmedia",
  },
  {
    title: "Agroprim",
    link: "./agroprim",
  },
  {
    title: "Anoual Plazza",
    link: "./anoual",
  },
  {
    title: "Arrabii Proprieties",
    link: "./arrabii",
  },
  {
    title: "Attika de Change",
    link: "./attika",
  },
  {
    title: "Bijouterie Al Kanz",
    link: "./alkanz",
  },
  {
    title: "Chimal Immobilière",
    link: "./chimal",
  },
  {
    title: "Cinar Habitat",
    link: "./cinar",
  },
  {
    title: "Commelibre",
    link: "./commelibre",
  },
  {
    title: "Costa Ice",
    link: "./costaice",
  },
  {
    title: "Digital Way",
    link: "./digitalway",
  },
  {
    title: "El Cosat",
    link: "./elcosat",
  },
  {
    title: "Electro Corner",
    link: "./electrocorner",
  },
  {
    title: "Espace Nador",
    link: "./espacenador",
  },
  {
    title: "FoodsnMoods Immo",
    link: "./foodsnmoods",
  },
  {
    title: "Gestion Energie Soutenable",
    link: "./gestionenergie",
  },
  {
    title: "Groupe Vision International",
    link: "./groupevision",
  },
  {
    title: "Gymnatex",
    link: "./gymnatex",
  },
  {
    title: "Histoire Loge",
    link: "./histoireloge",
  },
  {
    title: "Home Hotel",
    link: "./homehotel",
  },
  {
    title: "Immobilière Riad Al Ounss",
    link: "./riadalounss",
  },
  {
    title: "International Concassage",
    link: "./internationalconcassage",
  },
  {
    title: "Manzil Founti",
    link: "./manzilfounti",
  },
  {
    title: "Morjana Loge",
    link: "./morjanaloge",
  },
  {
    title: "Oujda City Center",
    link: "./oujdacity",
  },
  {
    title: "Promos Villas",
    link: "./promosvillas",
  },
  {
    title: "Satema",
    link: "./satema",
  },
  {
    title: "SC Invest",
    link: "./scinvest",
  },
  {
    title: "Seconad Immobilier",
    link: "./seconad",
  },
  {
    title: "Shandong",
    link: "./shandong",
  },
  {
    title: "Souben Hotel",
    link: "./soubenhotel",
  },
  {
    title: "Span Invest",
    link: "./spaninvest",
  },
  {
    title: "TFK Immo",
    link: "./tfkimmo",
  },
  {
    title: "Tingis Projects",
    link: "./tingisprojects",
  },
  {
    title: "Trimalux",
    link: "./trimalux",
  },
  {
    title: "Vision Clim",
    link: "./visionclim",
  },
  {
    title: "Vision Marocaine de Technologie",
    link: "./visionmarocaine",
  },
  {
    title: "W.A.T",
    link: "./wat",
  },
  {
    title: "Watherlife",
    link: "./watherlife",
  },
];
