import { useState } from "react";
import { Redirect, Switch, Routes, Route } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import FAQ from "./scenes/faq";
import Sallam from "./scenes/individus/Sallam";
import Said from "./scenes/individus/Said";
import Tawfik from "./scenes/individus/Tawfik";
import Abdallah from "./scenes/individus/Abdallah";
import Hassan from "./scenes/individus/Hassan";
import Seconad from "./scenes/societes/seconad"
import Agroprim from "./scenes/societes/agroprim"
import Anoual from "./scenes/societes/anoualplazza"
import Attika from "./scenes/societes/attikadechange"
import AlKanz from "./scenes/societes/bijouteriealkanz"
import Chimal from "./scenes/societes/chimalimmobiliere"
import Cinar from "./scenes/societes/cinarhabitat"
import Commelibre from "./scenes/societes/commelibre"
import CostaIce from "./scenes/societes/costaice"
import DigitalWay from "./scenes/societes/digitalway"
import RiadAlOunss from "./scenes/societes/immobiliereriadalounss"
import ElCosat from "./scenes/societes/elcosat"
import ElectroCorner from "./scenes/societes/electrocorner"
import EspaceNador from "./scenes/societes/espacenador"
import FoodsnMoods from "./scenes/societes/foodsnmoodsimmo"
import GestionEnergie from "./scenes/societes/gestionenergiesoutenable"
import GroupeVision from "./scenes/societes/groupevisioninternational"
import Gymnatex from "./scenes/societes/gymnatex"
import HistoireLoge from "./scenes/societes/histoireloge"
import HomeHotel from "./scenes/societes/homehotel"
import ManzilFounti from "./scenes/societes/manzilfounti"
import MorjanaLoge from "./scenes/societes/morjanaloge"
import Satema from "./scenes/societes/satema"
import SCInvest from "./scenes/societes/scinvest"
import Shandong from "./scenes/societes/shandong"
import SpanInvest from "./scenes/societes/spaninvest"
import Tingis from "./scenes/societes/tingisprojects"
import Trimalux from "./scenes/societes/trimalux"
import VisionClim from "./scenes/societes/visionclim"
import VisionMarocaine from "./scenes/societes/visionmarocainedetechnologie"
import ActifHolding from "./scenes/societes/actifholding"
import ActifMedia from "./scenes/societes/actifmedia"
import Arrabii from "./scenes/societes/arrabii"
import InternationalConcassage from "./scenes/societes/internationalconcassage"
import OujdaCity from "./scenes/societes/oujdacitycenter"
import PromosVillas from "./scenes/societes/promosvillas"
import SoubenHotel from "./scenes/societes/soubenhotel"
import TFKimmo from "./scenes/societes/tfkimmo"
import WAT from "./scenes/societes/wat"
import WatherLife from "./scenes/societes/watherlife"
import Error404 from "./scenes/error404"
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";


function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <Sidebar isSidebar={isSidebar} />
          <main className="content">
            <Topbar setIsSidebar={setIsSidebar} />
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/Sallam" element={<Sallam />} />
              <Route path="/Said" element={<Said />} />
              <Route path="/Abdallah" element={<Abdallah />} />
              <Route path="/Hassan" element={<Hassan />} />
              <Route path="/Tawfik" element={<Tawfik />} />
              <Route path="/seconad" element={<Seconad />} />
              <Route path="/agroprim" element={<Agroprim />} />
              <Route path="/anoual" element={<Anoual />} />
              <Route path="/attika" element={<Attika />} />
              <Route path="/alkanz" element={<AlKanz />} />
              <Route path="/chimal" element={<Chimal />} />
              <Route path="/cinar" element={<Cinar />} />
              <Route path="/commelibre" element={<Commelibre />} />
              <Route path="/costaice" element={<CostaIce />} />
              <Route path="/digitalway" element={<DigitalWay />} />
              <Route path="/riadalounss" element={<RiadAlOunss />} />
              <Route path="/elcosat" element={<ElCosat />} />
              <Route path="/electrocorner" element={<ElectroCorner />} />
              <Route path="/espacenador" element={<EspaceNador />} />
              <Route path="/foodsnmoods" element={<FoodsnMoods />} />
              <Route path="/gestionenergie" element={<GestionEnergie />} />
              <Route path="/groupevision" element={<GroupeVision />} />
              <Route path="/gymnatex" element={<Gymnatex />} />
              <Route path="/histoireloge" element={<HistoireLoge />} />
              <Route path="/homehotel" element={<HomeHotel />} />
              <Route path="/manzilfounti" element={<ManzilFounti />} />
              <Route path="/morjanaloge" element={<MorjanaLoge />} />
              <Route path="/satema" element={<Satema />} />
              <Route path="/scinvest" element={<SCInvest />} />
              <Route path="/shandong" element={<Shandong />} />
              <Route path="/spaninvest" element={<SpanInvest />} />
              <Route path="/tingisprojects" element={<Tingis />} />
              <Route path="/trimalux" element={<Trimalux />} />
              <Route path="/visionclim" element={<VisionClim />} />
              <Route path="/visionmarocaine" element={<VisionMarocaine />} />
              <Route path="/actifholding" element={<ActifHolding />} />
              <Route path="/actifmedia" element={<ActifMedia />} />
              <Route path="/arrabii" element={<Arrabii />} />
              <Route path="/internationalconcassage" element={<InternationalConcassage />} />
              <Route path="/oujdacity" element={<OujdaCity />} />
              <Route path="/promosvillas" element={<PromosVillas />} />
              <Route path="/soubenhotel" element={<SoubenHotel />} />
              <Route path="/tfkimmo" element={<TFKimmo />} />
              <Route path="/wat" element={<WAT />} />
              <Route path="/watherlife" element={<WatherLife />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="*" element={<Error404 />} />
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
