import { useTheme } from "@mui/material";
import { ResponsiveStream } from "@nivo/stream";
import { tokens } from "../theme";
import { StreamData as data } from "../data/Data";

const StreamChart = ({ isDashboard = false }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <ResponsiveStream
      data={data}
      keys={[
        "Immobilier",
        "Électroménager, électronique",
        "Change",
        "Glacier",
        "Commerce, import-export",
        "Agriculture, agroalimentaire",
        "Construction, équipement",
        "Valeurs mobilières et immobilières",
        "Salles et équipements de sport",
        "Joailleries et bijouteries",
        "Hébergement et restauration",
        "Fonderies",
        "Exploitation de sources d'eau",
      ]}
      margin={{ top: 50, right: 10, bottom: 230, left: 50 }}
      axisTop={{
        orient: "top",
        tickSize: 7,
        tickPadding: 14,
        tickRotation: 0,
        legend: "Année",
        legendOffset: -45,
        legendPosition: "middle",
      }}
      axisRight={null}
      axisBottom={null}
      axisLeft={{
        orient: "left",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Nombre de sociétés en exercice",
        legendOffset: -40,
        legendPosition: "middle",
      }}
      enableGridX={true}
      enableGridY={false}
      offsetType="silhouette"
      order="none"
      colors={{ scheme: "nivo" }}
      fillOpacity={1}
      borderColor={{ theme: "background" }}
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "#2c998f",
          size: 4,
          padding: 2,
          stagger: true,
        },
        {
          id: "squares",
          type: "patternSquares",
          background: "inherit",
          color: "#e4c912",
          size: 6,
          padding: 2,
          stagger: true,
        },
      ]}
      fill={[
        {
          match: {
            id: "Paul",
          },
          id: "dots",
        },
        {
          match: {
            id: "Marcel",
          },
          id: "squares",
        },
      ]}
      dotSize={8}
      dotColor={{ from: "color" }}
      dotBorderWidth={2}
      dotBorderColor={{
        from: "color",
        modifiers: [["darker", 0.7]],
      }}
      legends={[]}
    />
  );
};
export default StreamChart;
