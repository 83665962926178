import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";
import { RadialActifHolding as data } from "../../../data/Data";
import { ResponsiveRadialBar } from "@nivo/radial-bar";

const RadialBar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <ResponsiveRadialBar
      data={data}
      valueFormat={(value) =>
        `${Number(value).toLocaleString("fr-FR", {
          minimumFractionDigits: 2,
        })} %`
      }
      padding={0.4}
      cornerRadius={2}
      margin={{ top: 40, right: 150, bottom: 40, left: 40 }}
      radialAxisStart={{ tickSize: 5, tickPadding: 5, tickRotation: 0 }}
      circularAxisOuter={{ tickSize: 5, tickPadding: 12, tickRotation: 0 }}
      legends={[
        {
          anchor: "right",
          direction: "column",
          justify: false,
          translateX: 80,
          translateY: 0,
          itemsSpacing: 6,
          itemDirection: "left-to-right",
          itemWidth: 80,
          itemHeight: 15,
          itemTextColor: "#999",
          symbolSize: 15,
          symbolShape: "circle",
          effects: [
            {
              on: "hover",
              style: {
                itemTextColor: "#000",
              },
            },
          ],
        },
      ]}
    />
  );
};
export default RadialBar;
