import { Box, IconButton, useTheme } from "@mui/material";
import { useContext, useState } from "react";
import { ColorModeContext, tokens } from "../../theme";
import HubIcon from "@mui/icons-material/Hub";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import Tooltip from "@mui/material/Tooltip";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import SearchBar from "./SearchBar";
import { SearchData } from "../../data/Data";

const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const [isHovering, setIsHovering] = useState(false);
  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  return (
    <Box display="flex" justifyContent="space-between" p={2}>
      {/* SEARCH BAR */}
      <div className="App">
        <SearchBar
          sx={{ ml: 2, flex: 1 }}
          placeholder="Rechercher..."
          data={SearchData}
        ></SearchBar>
      </div>

      {/* ICONS */}
      <Box display="flex">
        <Tooltip title="Graphe relationnel">
          <IconButton
            href="https://reseau.ledesk-enquetechaabani.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <HubIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Revenir à l'enquête">
          <IconButton
            href="https://ledesk.ma/grandangle/reseau-chaabani-400-me-blanchis-par-un-bijoutier-de-casablanca-1-2/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <NewspaperIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Retourner à la page d'accueil">
          <IconButton
            href="https://ledesk.ma"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ExitToAppIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default Topbar;
