import { ResponsiveSankey } from '@nivo/sankey'
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";
import { SankeySoubenHotel as data } from "../../../data/Data";

const SankeyChart = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
  
    return (    
      <ResponsiveSankey
            valueFormat={value =>
                `${Number(value).toLocaleString('fr-FR', {
                     minimumFractionDigits: 2,
                 })} %`
            }
      data={data}
      margin={{ top: 40, right: 200, bottom: 40, left: 200 }}
      align="justify"
      colors={{ scheme: 'nivo' }}
      borderColor="#000000"
      nodeOpacity={1}
      nodeHoverOthersOpacity={0.35}
      nodeSpacing={24}
      nodeBorderWidth={0}
      nodeBorderColor={{
          from: 'color',
          modifiers: [
              [
                  'darker',
                  0.8
              ]
          ]
      }}
      nodeBorderRadius={3}
      linkOpacity={0.5}
      linkHoverOthersOpacity={0.1}
      linkContract={0}
      enableLinkGradient={true}
      labelPosition="outside"
      labelOrientation="horizontal"
      labelPadding={16}
      labelTextColor={{
          from: 'color',
          modifiers: [
              [
                  'darker',
                  1
              ]
          ]
      }}
      legends={[]}
        
                      
                    
    
    />
    );
};

export default SankeyChart;

