import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import EventIcon from "@mui/icons-material/Event";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import FlagIcon from "@mui/icons-material/Flag";
import Header from "../../../components/Header";
import StatBox from "../../../components/StatBox3";
import SankeyChart from "./SankeyChartTawfik";

const Test = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Tawfik Soudani" subtitle="Sociétés" />
      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="22/07/1973"
            subtitle="Naissance"
            icon={
              <EventIcon
                sx={{ color: colors.redAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="10"
            subtitle="Sociétés"
            icon={
              <PointOfSaleIcon
                sx={{ color: colors.redAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="Franco-tunisien"
            subtitle="Nationalité"
            icon={
              <FlagIcon
                sx={{ color: colors.redAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        {/* ROW 2 */}
        <Box
          gridColumn="span 12"
          gridRow="span 6"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="15px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h3"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Prises de participation en % (2005-2016)
              </Typography>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              ></Typography>
            </Box>
            <Box></Box>
          </Box>
          <Box height="800px" m="30px">
            <SankeyChart isTest={true} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Test;
