import { useState } from "react";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Box, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ApartmentIcon from "@mui/icons-material/Apartment";
import DomainAddIcon from "@mui/icons-material/DomainAdd";
import RoomServiceIcon from "@mui/icons-material/RoomService";
import AddchartIcon from "@mui/icons-material/Addchart";
import DiamondIcon from "@mui/icons-material/Diamond";
import TvIcon from "@mui/icons-material/Tv";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import IcecreamIcon from "@mui/icons-material/Icecream";
import NextWeekIcon from "@mui/icons-material/NextWeek";
import AgricultureIcon from "@mui/icons-material/Agriculture";
import ConstructionIcon from "@mui/icons-material/Construction";
import EngineeringIcon from "@mui/icons-material/Engineering";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import WaterIcon from "@mui/icons-material/Water";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  const Height = document.body.scrollHeight;

  return (
    <Box
      sx={{
        ".pro-sidebar": {
          height: "97.5vh",
          overflow: "auto",
          position: "-webkit-sticky",
          "max-height": Height,
          width: "320px",
          position: "sticky",
        },
        "& .collapsed": {
          width: "80px",
        },
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#EF183D !important",
        },
        "& .pro-menu-item.active": {
          color: "#EF183D !important",
        },
        "& .pro-menu-item": {
          color: "#353535 !important",
        },
        "& .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item":
          {
            "padding-left": "10px",
          },
        ".pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item.has-arrow":
          {
            "padding-left": "0px",
          },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <Box display="flex" justifyContent="center" alignItems="center">
            <img
              alt="profile-userc"
              width="30px"
              height="30px"
              src={`../../assets/userc.png`}
              style={{
                margin: "8px 0 20px 0",
                color: colors.grey[100],
              }}
            />
          </Box>
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <ArrowForwardIosIcon /> : undefined}
            style={{
              margin: "15px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <MenuItem
                  onClick={() => setIsCollapsed(!isCollapsed)}
                  icon={<ArrowBackIosIcon />}
                  style={{
                    margin: "-4px 10px 5px 200px",
                    color: colors.grey[100],
                  }}
                ></MenuItem>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="profile-user"
                  width="105px"
                  height="100px"
                  src={`../../assets/ledesk1.png`}
                  style={{
                    cursor: "pointer",
                    borderRadius: "50%",
                    marginLeft: "20px",
                  }}
                />
              </Box>
              <Box textAlign="center">
                <Typography
                  variant="h2"
                  color="#ef183d"
                  fontWeight="bold"
                  sx={{ m: "-170px 0 0 0" }}
                  style={{ marginRight: "4px" }}
                >
                  le Desk
                </Typography>
                <Typography variant="h4" color="#353535"></Typography>
              </Box>
            </Box>
          )}

          <Box paddingLeft={isCollapsed ? undefined : "0%"}>
            <Typography
              variant="h6"
              color={colors.grey[500]}
              sx={{ m: "150px 0 5px 20px" }}
            >
              Général
            </Typography>
            <Item
              title="Données globales"
              to="/"
              icon={<AnalyticsIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Typography
              variant="h6"
              color={colors.grey[500]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Tiers
            </Typography>
            <SubMenu
              title="Individus"
              onClick={() => setIsCollapsed(false)}
              icon={<ContactsOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            >
              <Item
                title="Sallam Chaâbani"
                to="/Sallam"
                icon={<AccountCircleIcon />}
                selected={selected}
                setSelected={setSelected}
              ></Item>
              <Item
                title="Said Chaâbani"
                to="/Said"
                icon={<AccountCircleIcon />}
                selected={selected}
                setSelected={setSelected}
              ></Item>
              <Item
                title="Abdallah Chaâbani"
                to="/Abdallah"
                icon={<AccountCircleIcon />}
                selected={selected}
                setSelected={setSelected}
              ></Item>
              <Item
                title="Hassan Chaâbani"
                to="/Hassan"
                icon={<AccountCircleIcon />}
                selected={selected}
                setSelected={setSelected}
              ></Item>
              <Item
                title="Tawfik Soudani"
                to="/Tawfik"
                icon={<AccountCircleIcon />}
                selected={selected}
                setSelected={setSelected}
              ></Item>
            </SubMenu>
            <Typography
              variant="h6"
              color={colors.grey[500]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Sociétés
            </Typography>
            <SubMenu
              title="Sociétés"
              onClick={() => setIsCollapsed(false)}
              icon={<ApartmentIcon />}
              selected={selected}
              setSelected={setSelected}
            >
              <SubMenu
                title="Immobilier"
                icon={<DomainAddIcon />}
                selected={selected}
                setSelected={setSelected}
              >
                <Item
                  title="Anoual Plazza"
                  to="/anoual"
                  icon={<DomainAddIcon />}
                  selected={selected}
                  setSelected={setSelected}
                ></Item>
                <Item
                  title="Arrabii Proprieties"
                  to="/arrabii"
                  icon={<DomainAddIcon />}
                  selected={selected}
                  setSelected={setSelected}
                ></Item>
                <Item
                  title="Chimal Immobilière"
                  to="/chimal"
                  icon={<DomainAddIcon />}
                  selected={selected}
                  setSelected={setSelected}
                ></Item>
                <Item
                  title="Cinar Habitat"
                  to="/cinar"
                  icon={<DomainAddIcon />}
                  selected={selected}
                  setSelected={setSelected}
                ></Item>
                <Item
                  title="FoodsnMoods Immo"
                  to="/foodsnmoods"
                  icon={<DomainAddIcon />}
                  selected={selected}
                  setSelected={setSelected}
                ></Item>
                <Item
                  title="Histoire Loge"
                  to="/histoireloge"
                  icon={<DomainAddIcon />}
                  selected={selected}
                  setSelected={setSelected}
                ></Item>
                <Item
                  title="Immobilière Riad Al Ounss"
                  to="/riadalounss"
                  icon={<DomainAddIcon />}
                  selected={selected}
                  setSelected={setSelected}
                ></Item>
                <Item
                  title="Manzil Founti"
                  to="/manzilfounti"
                  icon={<DomainAddIcon />}
                  selected={selected}
                  setSelected={setSelected}
                ></Item>
                <Item
                  title="Morjana Loge"
                  to="/morjanaloge"
                  icon={<DomainAddIcon />}
                  selected={selected}
                  setSelected={setSelected}
                ></Item>
                <Item
                  title="Promos Villas"
                  to="/promosvillas"
                  icon={<DomainAddIcon />}
                  selected={selected}
                  setSelected={setSelected}
                ></Item>
                <Item
                  title="Seconad Immobilier"
                  to="/seconad"
                  icon={<DomainAddIcon />}
                  selected={selected}
                  setSelected={setSelected}
                ></Item>
                <Item
                  title="Span Invest"
                  to="/spaninvest"
                  icon={<DomainAddIcon />}
                  selected={selected}
                  setSelected={setSelected}
                ></Item>
                <Item
                  title="TFK Immo"
                  to="/tfkimmo"
                  icon={<DomainAddIcon />}
                  selected={selected}
                  setSelected={setSelected}
                ></Item>
                <Item
                  title="Tingis Projects"
                  to="/tingisprojects"
                  icon={<DomainAddIcon />}
                  selected={selected}
                  setSelected={setSelected}
                ></Item>
              </SubMenu>
              <SubMenu
                title="Hébergement, restauration"
                icon={<RoomServiceIcon />}
                selected={selected}
                setSelected={setSelected}
              >
                <Item
                  title="Home Hotel"
                  to="/homehotel"
                  icon={<RoomServiceIcon />}
                  selected={selected}
                  setSelected={setSelected}
                ></Item>
                <Item
                  title="Shandong"
                  to="/shandong"
                  icon={<RoomServiceIcon />}
                  selected={selected}
                  setSelected={setSelected}
                ></Item>
              </SubMenu>
              <SubMenu
                title="Valeurs mobilières & immobilières"
                icon={<AddchartIcon />}
                selected={selected}
                setSelected={setSelected}
              >
                <Item
                  title="Actif Holding"
                  to="/actifholding"
                  icon={<AddchartIcon />}
                  selected={selected}
                  setSelected={setSelected}
                ></Item>
                <Item
                  title="Actif Media Pole"
                  to="/actifmedia"
                  icon={<AddchartIcon />}
                  selected={selected}
                  setSelected={setSelected}
                ></Item>
                <Item
                  title="International Concassage"
                  to="/internationalconcassage"
                  icon={<AddchartIcon />}
                  selected={selected}
                  setSelected={setSelected}
                ></Item>
                <Item
                  title="Oujda City Center"
                  to="/oujdacity"
                  icon={<AddchartIcon />}
                  selected={selected}
                  setSelected={setSelected}
                ></Item>
                <Item
                  title="SC Invest"
                  to="/scinvest"
                  icon={<AddchartIcon />}
                  selected={selected}
                  setSelected={setSelected}
                ></Item>
                <Item
                  title="Souben Hotel"
                  to="/soubenhotel"
                  icon={<AddchartIcon />}
                  selected={selected}
                  setSelected={setSelected}
                ></Item>
                <Item
                  title="W.A.T"
                  to="/wat"
                  icon={<AddchartIcon />}
                  selected={selected}
                  setSelected={setSelected}
                ></Item>
              </SubMenu>
              <SubMenu
                title="Electroménager, électronique"
                icon={<TvIcon />}
                selected={selected}
                setSelected={setSelected}
              >
                <Item
                  title="El Cosat"
                  to="/elcosat"
                  icon={<TvIcon />}
                  selected={selected}
                  setSelected={setSelected}
                ></Item>
                <Item
                  title="Electro Corner"
                  to="/electrocorner"
                  icon={<TvIcon />}
                  selected={selected}
                  setSelected={setSelected}
                ></Item>
                <Item
                  title="Espace Nador"
                  to="/espacenador"
                  icon={<TvIcon />}
                  selected={selected}
                  setSelected={setSelected}
                ></Item>
                <Item
                  title="Groupe Vision International"
                  to="/groupevision"
                  icon={<TvIcon />}
                  selected={selected}
                  setSelected={setSelected}
                ></Item>
                <Item
                  title="Vision Marocaine de Technologie"
                  to="/visionmarocaine"
                  icon={<TvIcon />}
                  selected={selected}
                  setSelected={setSelected}
                ></Item>
              </SubMenu>
              <SubMenu
                title="Change"
                icon={<CurrencyExchangeIcon />}
                selected={selected}
                setSelected={setSelected}
              >
                <Item
                  title="Attika de Change"
                  to="/attika"
                  icon={<CurrencyExchangeIcon />}
                  selected={selected}
                  setSelected={setSelected}
                ></Item>
              </SubMenu>
              <SubMenu
                title="Joailleries et bijouteries"
                icon={<DiamondIcon />}
                selected={selected}
                setSelected={setSelected}
              >
                <Item
                  title="Bijouterie Al-Kanz"
                  to="/alkanz"
                  icon={<DiamondIcon />}
                  selected={selected}
                  setSelected={setSelected}
                ></Item>
              </SubMenu>
              <SubMenu
                title="Glaciers"
                icon={<IcecreamIcon />}
                selected={selected}
                setSelected={setSelected}
              >
                <Item
                  title="Costa Ice"
                  to="/costaice"
                  icon={<IcecreamIcon />}
                  selected={selected}
                  setSelected={setSelected}
                ></Item>
              </SubMenu>
              <SubMenu
                title="Commerce, import-export"
                icon={<NextWeekIcon />}
                selected={selected}
                setSelected={setSelected}
              >
                <Item
                  title="Commelibre"
                  to="/commelibre"
                  icon={<NextWeekIcon />}
                  selected={selected}
                  setSelected={setSelected}
                ></Item>
                <Item
                  title="Digital Way"
                  to="/digitalway"
                  icon={<NextWeekIcon />}
                  selected={selected}
                  setSelected={setSelected}
                ></Item>
              </SubMenu>
              <SubMenu
                title="Agriculture et agroalimentaire"
                icon={<AgricultureIcon />}
                selected={selected}
                setSelected={setSelected}
              >
                <Item
                  title="Agroprim"
                  to="/agroprim"
                  icon={<AgricultureIcon />}
                  selected={selected}
                  setSelected={setSelected}
                ></Item>
                <Item
                  title="Satema"
                  to="/satema"
                  icon={<AgricultureIcon />}
                  selected={selected}
                  setSelected={setSelected}
                ></Item>
              </SubMenu>
              <SubMenu
                title="Construction, équipement"
                icon={<ConstructionIcon />}
                selected={selected}
                setSelected={setSelected}
              >
                <Item
                  title="Gestion Energie Soutenable"
                  to="/gestionenergie"
                  icon={<ConstructionIcon />}
                  selected={selected}
                  setSelected={setSelected}
                ></Item>
                <Item
                  title="Vision Clim"
                  to="/visionclim"
                  icon={<ConstructionIcon />}
                  selected={selected}
                  setSelected={setSelected}
                ></Item>
              </SubMenu>
              <SubMenu
                title="Fonderie"
                icon={<EngineeringIcon />}
                selected={selected}
                setSelected={setSelected}
              >
                <Item
                  title="Trimalux"
                  to="/trimalux"
                  icon={<EngineeringIcon />}
                  selected={selected}
                  setSelected={setSelected}
                ></Item>
              </SubMenu>
              <SubMenu
                title="Salles et équipements sportifs"
                icon={<FitnessCenterIcon />}
                selected={selected}
                setSelected={setSelected}
              >
                <Item
                  title="Gymnatex"
                  to="/gymnatex"
                  icon={<FitnessCenterIcon />}
                  selected={selected}
                  setSelected={setSelected}
                ></Item>
              </SubMenu>
              <SubMenu
                title="Exploitation de sources d'eau"
                icon={<WaterIcon />}
                selected={selected}
                setSelected={setSelected}
              >
                <Item
                  title="Wather Life"
                  to="/watherlife"
                  icon={<WaterIcon />}
                  selected={selected}
                  setSelected={setSelected}
                ></Item>
              </SubMenu>
            </SubMenu>
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
