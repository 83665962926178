import {
  Box, Typography,
  useTheme
} from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import LineChart from "../../components/LineChartGlobal";
import BarChart from "../../components/BarChartGlobal";
import StreamChart from "../../components/StreamChartGlobal";
import PieChart from "../../components/PieChartGlobal";
import useMediaQuery from "@mui/material/useMediaQuery";

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const matches = useMediaQuery("(min-width:600px)");

  const styles = (theme) => ({
    [theme.breakpoints.down("xs")]: {
      display: "row",
    },

    [theme.breakpoints.down("sm")]: {
      display: "block",
    },

    [theme.breakpoints.up("md")]: {
      display: "block",
    },

    [theme.breakpoints.up("lg")]: {
      display: "grid",
    },

    [theme.breakpoints.up("xl")]: {
      display: "grid",
    },
  });

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header
          title="Réseau Chaâbani"
          subtitle="Informations financières générales"
        />
      </Box>

      {/* GRID & CHARTS */}
      <Box
        display={styles}
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 2 */}
        <Box
          sx={{ mb: 2 }}
          gridColumn="span 12"
          gridRow="span 2"
          gap="20px"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                CA cumulé 2005-2021:
              </Typography>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
                ↳ 1 430 113 280 dirhams
              </Typography>
            </Box>
          </Box>
          <Box height="230px" m="-25px 0 0 0">
            <LineChart isDashboard={true} />
          </Box>
        </Box>

        {/* ROW 3 */}
        <Box
          sx={{ mb: 2 }}
          gridColumn="span 6"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
          p="20px"
        >
          <Typography variant="h5" fontWeight="600">
            Répartition des sociétés par secteur
          </Typography>
          <Box height="350px" mt="25px">
            <PieChart isDashboard={true} />
          </Box>
        </Box>
        <Box
          sx={{ mb: 2 }}
          gridColumn="span 6"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
          padding="20px"
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 20px 1 20px" }}
          >
            CA par secteur (en MDH)
          </Typography>
          <Box height="350px" mt="-40px">
            <BarChart isDashboard={true} />
          </Box>
        </Box>

        {/* ROW 4 */}
        <Box
          sx={{ mb: 0 }}
          gridColumn="span 12"
          gridRow="span 4"
          backgroundColor={colors.primary[400]}
          p="25px"
        >
          <Typography variant="h5" fontWeight="600">
            Évolution du portefeuille d'investissements: 2005 (année 0) - 2016
            (année 11)
          </Typography>
          <Box height="500px" mt="20px">
            <StreamChart isDashboard={true} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
