import React from 'react';
import { Box, Button, Container, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';

export default function Error() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh'
      }}
    >
      <Container maxWidth="md">
        <Grid container spacing={2}>
          <Grid xs={6}>
            <Typography variant="h1">
              404
            </Typography>
            <Typography variant="h6">
            <p>
              La page liée à cet individu ou à cette société nexiste pas.
              </p>
              Seuls les suspects ainsi que leurs participations financières directes sont recensés dans ce portail.
              <p>
              Nous vous invitons à reprendre votre exploration du graphe relationnel ou du dashboard. 
              </p>
            </Typography>
            <Box display="flex" justifyContent="space-between" p={1}>
            <Button variant="contained" href="/">
              Revenir à l'accueil
            </Button>
            </Box>

            <Box display="flex" justifyContent="space-between" p={1}>
            <Button variant="contained" href="https://reseau.ledesk-enquetechaabani.com" target="_blank" rel="noopener noreferrer">
              Revenir au graphe relationnel
            </Button>
            </Box>

          </Grid>
          <Grid xs={6}>
            <img
                  src={`../../assets/centrality.png`}
                  alt=""
              width={320} 
              height={320}
              style={{
                marginLeft: "50px",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}